<template>
  <div class="wrapper mr-2">
    <div class="container-fluid pl-0">
        <div class="editor_area">
            <!---------Treatment Histroy---------->
            <div class="pt-3">
              <p><span class="yellow_chip">Treatment History</span></p>
              <div class="tabular_data">
                <table class="table" id="mytable">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Duration</th>
                      <th>Response</th>
                      <th>Reason for stopping</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="greentext">
                        <select id="selectitems" class="form-select">
                          <option selected>Corticosteroids</option>
                          <option>MTX</option>
                          <option>HCQS</option>
                          <option>Sulfasalazine</option>
                          <option>Leflunomide</option>
                          <option>Ayurvedic</option>
                          <option>Homeopathic</option>
                        </select>
                      </td>
                      <td><input type="text" class="form-control" :id="'treatment' + componentId"/></td>
                      <td><input type="text" class="form-control" /></td>
                      <td><input type="text" class="form-control" /></td>
                    </tr>
                    <tr>
                      <td class="greentext">
                        <select id="selectitems" class="form-select">
                          <option>Corticosteroids</option>
                          <option selected>MTX</option>
                          <option>HCQS</option>
                          <option>Sulfasalazine</option>
                          <option>Leflunomide</option>
                          <option>Ayurvedic</option>
                          <option>Homeopathic</option>
                        </select>
                      </td>
                      <td><input type="text" class="form-control" /></td>
                      <td><input type="text" class="form-control" /></td>
                      <td><input type="text" class="form-control" /></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="5" class="bg-light">
                        <span
                          style="cursor: pointer"
                          @click="copyRow()"
                          id="add_element"
                          ><i class="bi bi-plus-circle me-2"></i> Add New
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <!--tabular_data-->
            </div>
            <!---------Treatment Histroy ends---------->
          </div>
    </div>
  </div>
  <!--wrapper-->
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
let propsData = defineProps({
  componentId: {
    default: "",
  },
});
onMounted(() => {
  document.getElementById('treatment' + propsData.componentId).focus()
})
function copyRow() {
  var html = $("#mytable tbody tr:last").html();
  $("#mytable tbody tr:last").after("<tr>" + html + "</tr>");
}

</script>