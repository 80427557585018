import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import './assets/style.css'
import VueDOMPurifyHTML from 'vue-dompurify-html';

createApp(App).use(store).use(VueDOMPurifyHTML).mount('#app')
