<template>
  <indexComponent/>
</template>

<script>
import EditorComponent from './components/EditorComponent.vue';
import indexComponent from './components/index.vue';

export default {
  name: 'App',
  components: {
    EditorComponent,
    indexComponent
  }
}
</script>


