<template>
  <div class="right_sidebar pt-5">
    <div class="card border-0 rounded-0">
      <div class="card-header border-0 rounded-0 bg-white p-0">
        <div class="date_slider bg-light rounded-3">
          <button class="btn btn-link" @click="slide(-1)" :disabled="currentIndex == 0">
            <i class="bi bi-arrow-left"></i>
          </button>
          <Transition name="slide-fade">
            <div>
              <span class="date-slide" :key="currentIndex">{{ dates[currentIndex] }}</span>
            </div>
          </Transition>

          <button class="btn btn-link" @click="slide(1)" :disabled="currentIndex == dates.length - 1">
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
        <ul class="nav nav-pills mt-3 mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation" v-for="(tab, index) in tabsData.tabs" :key="index">
            <button class="nav-link" :class='tab.name == currentTab ? "active" : ""' :id="tab.id" data-bs-toggle="pill"
              :data-bs-target="'#' + tab.target" type="button" role="tab" :aria-controls="tab.target"
              aria-selected="true"><img :src="previewImage(tab.icon)" class="mr-2" alt="">{{ tab.name }}</button>
          </li>
        </ul>
      </div>
      <div class="card-body pb-0 px-0">
        <!-- <p><span class="orange_chip">{{ value.dateTime }}</span></p> -->
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade" :class="currentTab == 'All' ? 'active show' : ''" id="pills-all" role="tabpanel"
            aria-labelledby="pills-all-tab">
            <div class="pb-2" v-for="(value, index) in tabsData.all" :key="index">
              <p><span class="orange_chip">{{ value.dateTime }}</span></p>
              <div class="contentbox mb-3 bg-light">
                <!-- <p><span class="orange_chip">Aug 28, 2022 09:15AM</span></p> -->
                <p><i class="bi bi-clipboard mr-2"></i>History</p>
                <p>
                  {{ value.data.history }}
                </p>
              </div>
              <div class="contentbox mb-3 bg-light">
                <p><i class="bi bi-clipboard mr-2"></i>Diagnosis</p>
                <p>
                  {{ value.data.diagnosis }}
                </p>
              </div>
              <div class="contentbox mb-3 bg-light">
                <p><i class="bi bi-clipboard mr-2"></i>Advice</p>
                <p>
                  {{ value.data.advise }}
                </p>
              </div>
              <div class="contentbox mb-3 bg-light">
                <p><i class="bi bi-clipboard mr-2"></i>Prescription</p>
                <p>
                  {{ value.data.prescription }}
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" :class="currentTab == 'History' ? 'active show' : ''" id="pills-history"
            role="tabpanel" aria-labelledby="pills-history-tab">
            <div v-for="(value, index) in tabsData.history" :key="index">
              <p><span class="orange_chip mt-2">{{ value.dateTime }}</span></p>
              <div class="contentbox bg-light">
                <p> <i class="bi bi-clipboard mr-2"></i>History </p>
                <p> {{ value.data }} </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" :class="currentTab == 'Diagnosis' ? 'active show' : ''" id="pills-diagnosis"
            role="tabpanel" aria-labelledby="pills-diagnosis-tab">
            <div v-for="(value, index) in tabsData.diagnosis" :key="index">
              <p><span class="orange_chip mt-2">{{ value.dateTime }}</span></p>
              <div class="contentbox bg-light">
                <p><i class="bi bi-clipboard mr-2"></i>Diagnosis</p>
                <p>
                  {{ value.data }}
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" :class="currentTab == 'Advise' ? 'active show' : ''" id="pills-advice"
            role="tabpanel" aria-labelledby="pills-advice-tab">
            <div v-for="(value, index) in tabsData.diagnosis" :key="index">
              <p><span class="orange_chip mt-2">{{ value.dateTime }}</span></p>
              <div class="contentbox bg-light">
                <p><i class="bi bi-clipboard mr-2"></i>Advice</p>
                <p>
                  {{ value.data }}
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" :class="currentTab == 'Prescription' ? 'active show' : ''" id="pills-prescription"
            role="tabpanel" aria-labelledby="pills-prescription-tab">
            <div v-for="(value, index) in tabsData.diagnosis" :key="index">
              <p><span class="orange_chip mt-2">{{ value.dateTime }}</span></p>
              <div class="contentbox bg-light">
                <p><i class="bi bi-clipboard mr-2"></i>Prescription</p>
                <p>
                  {{ value.data }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script setup>
import {
  ref,
  onMounted,
  defineProps,
  watch,
  computed,
  reactive,
  defineExpose,
} from "vue";
import tabsData from '../data.json';

let propsData = defineProps([
  "currentIndex",
  "currentTab"
])
function previewImage(path) {
  return require('../assets/icons/' + path)
}

const currentIndex = ref(0);
const dates = ref([
  "Aug 28, 2022 09:15AM",
  "Aug 29, 2022 09:15AM",
  "Aug 30, 2022 09:15AM",
  "Aug 31, 2022 09:15AM"
])

const slide = (direction) => {
  const newIndex = currentIndex.value + direction;
  if (newIndex >= 0 && newIndex < dates.value.length) {
    currentIndex.value = newIndex;
  }
}
</script>