
<template>
  <div id="editorjs" />
  <button id="save-button">Save</button>
  <pre id="output"></pre>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import EditorJS from '@editorjs/editorjs';
  import Header from '@editorjs/header'; 
  import List from '@editorjs/list';
  import Image  from './image'
  import History from './History/historyAutocomplete'
  // import Test from './test'

  const editor = new EditorJS({
    holder: 'editorjs',
    tools: {
      header: Header,
      list: List,
      image:Image,
      history: History,
      test : Test
    }
  });

  
  editor.isReady
    .then(() => {
      console.log('Editor.js is ready to work!');
      /** Do anything you need after editor initialization */
    })
    .catch((reason) => {
      console.log(`Editor.js initialization failed because of ${reason}`);
    });

    onMounted(() => {
        const saveButton = document.getElementById('save-button');
        const output = document.getElementById('output');
        saveButton.addEventListener('click', () => {
        editor.save().then( savedData => {
          output.innerHTML = JSON.stringify(savedData, null, 4);
        })
      })
    })

    // editor.save().then((outputData) => {
    //   console.log('Article data: ', outputData)
    // }).catch((error) => {
    //   console.log('Saving failed: ', error)
    // });

</script>