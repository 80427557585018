<template>
  <div class="modal fade" id="printView" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Print View</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body py-0">
          <table
            width="100%"
            cellpadding="0"
            cellspacing="0"
            border="0"
            style="
              margin: 0 auto;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 15px;
            "
            :id="id"
          >
            <tbody>
              <tr>
                <td>
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td style="padding: 3px 5px 3px 0">Name: Pinki Mahato</td>
                      <td style="padding: 3px 5px 3px 5px">Patient ID: 20231204003</td>
                      <td style="padding: 3px 0 3px 5px">Consultation Dt: 13/01/2024</td>
                    </tr>
                    <tr>
                      <td style="padding: 3px 5px 3px 0">Age: 28 Year(s)</td>
                      <td style="padding: 3px 5px 3px 5px">Gender: Female</td>
                      <td style="padding: 3px 0 3px 5px">HT/WT: 152 cm/49.2 kg</td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td style="border-bottom: 1px solid #000000; padding: 10px 0"></td>
              </tr>

              <tr>
                <td style="padding-top: 10px">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td
                        colspan="2"
                        align="center"
                        style="padding: 3px 3px 10px 0; text-align: center"
                      >
                        Rheumatology Clinic
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        style="
                          padding: 8px 5px;
                          background-color: #acacac;
                          font-size: 18px;
                        "
                      >
                        Clinical Notes
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="padding: 10px 0 0 0"></td>
                    </tr>

                    <template v-for="block in getPrintDataBlocks" :key="block">
                      <template
                        v-if="
                          printData[block].type == 'autocomplete' && printTable[block]
                        "
                      >
                        <tr>
                          <td valign="top" width="25%" style="padding: 3px 5px 3px 0">
                            {{ printData[block].title }}
                          </td>
                          <td style="padding: 3px 0 3px 5px">
                            <p
                              class="mb-0"
                              v-for="section in printData[block].data"
                              :key="section.componentId"
                              v-dompurify-html="getProcessedHtml(section.innerHtmlData)"
                            ></p>
                          </td>
                        </tr>
                      </template>
                      <tr
                        v-if="
                          printData[block].subType == 'prescription' && printTable[block]
                        "
                      >
                        <td
                          colspan="2"
                          style="border-bottom: 1px solid #000000; padding: 20px 0 0 0"
                        ></td>
                      </tr>
                      <template v-if="printData[block].type == 'widget'">
                        <template
                          v-if="
                            printData[block].subType == 'prescription' &&
                            printTable[block]
                          "
                        >
                          <tr>
                            <td colspan="2" style="padding-top: 10px">
                              <table
                                width="100%"
                                cellpadding="0"
                                cellspacing="0"
                                border="0"
                              >
                                <tr>
                                  <td style="padding: 10px 3px 15px 0">
                                    Prescription (Rx)
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 8px 5px; background-color: #acacac">
                                    Drug Name
                                  </td>
                                  <td style="padding: 8px 5px; background-color: #acacac">
                                    Dose/Instruction
                                  </td>
                                  <td style="padding: 8px 5px; background-color: #acacac">
                                    Days
                                  </td>
                                </tr>

                                <template
                                  v-for="section in printData[block].data"
                                  :key="section.componentId"
                                >
                                  <tr
                                    v-for="(medicine, medIndex) in section.data"
                                    :key="section.componentId + medicine.Type + medIndex"
                                  >
                                    <td valign="top" style="padding: 8px 5px">
                                      {{
                                        medicine["TotalMedicines"] +
                                        " " +
                                        medicine["Dosage"]
                                      }}
                                      <br />
                                      ({{ medicine["Medicine Name"] }})
                                    </td>
                                    <td style="padding: 8px 5px">
                                      {{
                                        medicine["Frequency"] +
                                        ", " +
                                        medicine["Instructions"]
                                      }}
                                      <br />
                                      {{ medicine["Hindi Instructions"] }}
                                      <br />
                                      <!-- Saturday Evening -->
                                    </td>
                                    <td style="padding: 8px 5px">
                                      {{ medicine["Duration"] }}
                                    </td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  align="right"
                  style="border-bottom: 1px solid #000000; padding: 20px 0 0 0"
                ></td>
              </tr>
              <tr>
                <td
                  align="right"
                  style="padding-top: 20px; padding-bottom: 20px; text-align: right"
                >
                  Dr. Rutviz Mistry
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-custom"
            @click="handlePrintClick"
          >
            Print
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- print model -->
  <div
    class="modal fade"
    id="print"
    tabindex="-1"
    aria-labelledby="printLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="printLabel">Print</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="handlePrintViewModalClose"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="form-check"
            v-for="block in getPrintDataBlocks"
            :key="'checkbox-' + block"
          >
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              :id="'checkbox-' + block + 'id'"
              v-model="printTable[block]"
            />
            <label
              class="form-check-label"
              style="text-transform: capitalize"
              :for="'checkbox-' + block + 'id'"
            >
              {{ block }}
            </label>
          </div>
          <p v-if="getPrintDataBlocks?.length == 0">No Tool to select</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-custom"
            @click="handlePrintView"
            :disabled="Object.keys(printTable).filter((el) => printTable[el]).length == 0"
          >
            View
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  watch,
  computed,
  reactive,
  defineExpose,
  nextTick,
} from "vue";

let propsData = defineProps(["exportData", "handlePrint"]);
let allowedPrintBlocks = ref(["history", "diagnosis", "prescription", "advice"]);

let id = ref("exportClinicalNotes");
let printTable = ref({
  history: false,
  diagnosis: false,
  prescription: false,
  advice: false,
});

let printData = computed(() => {
  let returnVal = {};
  propsData.exportData?.blocks?.forEach((block, i) => {
    returnVal[block.type] = { ...returnVal[block.type] };
    returnVal[block.type]["data"] = returnVal[block.type]["data"]
      ? [...returnVal[block.type]["data"]]
      : [];
    returnVal[block.type]["data"].push(block.data);
    returnVal[block.type]["type"] = block.data.type;
    returnVal[block.type]["subType"] = block.data.subType;
    returnVal[block.type]["title"] = block.data.name;
  });

  console.log("returnVal: ", returnVal);
  return returnVal;
});

let getPrintDataBlocks = computed(() => {
  let returnVal = [];
  let keys = Object.keys(printData.value);
  console.log("returnVal: ", returnVal);
  keys.forEach((el) => {
    console.log("printData.value[el].type: ", printData.value[el].type);
    if (printData.value[el].type == "autocomplete") {
      console.log("el: ", el);
      returnVal.push(el);
    }
  });
  if (keys.includes("prescription")) {
    returnVal = [...returnVal, ...["prescription"]];
  }
  console.log("getPrintDataBlocks: ", returnVal);
  return returnVal;
});

let getProcessedHtml = (htmlData) => {
  return htmlData.replaceAll("contenteditable", "sss").replaceAll("id", "pid");
};

let handlePrintView = (e) => {
  $("#print").modal("hide");
  setTimeout(() => {
    $("#printView").modal("show");
  }, 100);
};

let handlePrintViewModalClose = (e) => {
  setTimeout(() => {
    printTable.value = [];
  }, 100);
};

let handlePrintClick = (e) => {
  propsData.handlePrint();
  setTimeout(async () => {
    $("#printView").modal("hide");
    await nextTick();
    handlePrintViewModalClose();
  }, 1000);
};
</script>
