export default (noUiSlider, wNumb, componentId, componentSelector) => {
  let joints;
  let joint_id;
  let state = "#ffffff";

  let initHomer = () => {
    joints = $("#today-homer" + componentId + " circle");

    $(componentSelector + " .btn-normal").on("click", function () {
      $(componentSelector + " .btn-tender").css("color", "white");
      $(componentSelector + " .btn-swollen").css("color", "white");
      $(componentSelector + " .btn-both").css("color", "white");
      $(this).css("color", "black");
      state = "#ffffff";
    });

    $(componentSelector + " .btn-tender").on("click", function () {
      $(componentSelector + " .btn-normal").css("color", "grey");
      $(componentSelector + " .btn-swollen").css("color", "white");
      $(componentSelector + " .btn-both").css("color", "white");
      $(this).css("color", "black");
      state = "#fcec52";
    });

    $(componentSelector + " .btn-swollen").on("click", function () {
      $(componentSelector + " .btn-normal").css("color", "grey");
      $(componentSelector + " .btn-tender").css("color", "white");
      $(componentSelector + " .btn-both").css("color", "white");
      $(this).css("color", "black");
      state = "#f0ad4e";
    });

    $(componentSelector + " .btn-both").on("click", function () {
      $(componentSelector + " .btn-normal").css("color", "grey");
      $(componentSelector + " .btn-swollen").css("color", "white");
      $(componentSelector + " .btn-tender").css("color", "white");
      $(this).css("color", "black");
      state = "#db5461";
    });

    joints.each(function (i) {
      $(this).css("fill", state);
    })
    calculateJointsType()

    joints.on("click", function () {
      console.log('$(this): ', $(this));
      $(this).css("fill", state);
      calculateJointsType()
      calculate();
    });

    let esrSlider = document.getElementById('slider-esr' + componentId);

    noUiSlider.create(esrSlider, {
      start: [0],
      tooltips: [wNumb({
        decimals: 1
      })],
      range: {
        'min': [0],
        'max': [50]
      }
    });

    esrSlider.noUiSlider.on('update', function (values, handle) {
      calculate();
    });

    let crpSlider = document.getElementById('slider-crp' + componentId);
    noUiSlider.create(crpSlider, {
      start: [0],
      tooltips: [wNumb({
        decimals: 1
      })],
      range: {
        'min': [0],
        'max': [10]
      }
    });

    crpSlider.noUiSlider.on('update', function (values, handle) {
      calculate();
    });

    let pghSlider = document.getElementById('slider-pgh' + componentId);
    noUiSlider.create(pghSlider, {
      start: [0],
      tooltips: [wNumb({
        decimals: 0
      })],
      range: {
        'min': [0],
        'max': [100]
      }
    });

    pghSlider.noUiSlider.on('update', function (values, handle) {
      calculate();
    });
  }

  let calculateJointsType = () => {
    let s28 = 0;
    let t28 = 0;
    let s68 = 0;
    let t68 = 0;
    joints.each(function (i) {
      let fill = $(this).css("fill");
      let num = $(this).attr("id").substring(3);
      switch (fill) {
        case "rgb(255, 255, 255)":
          break;
        case "rgb(252, 236, 82)":
          // tender
          t68 += 1;
          if (num <= 28) t28 += 1;
          break;
        case "rgb(240, 173, 78)":
          // swollen
          s68 += 1;
          if (num <= 28) s28 += 1;
          break;
        case "rgb(219, 84, 97)":
          // both
          s68 += 1;
          t68 += 1;
          if (num <= 28) { s28 += 1; t28 += 1 }
          break;
        default:
      }
    });
    $(componentSelector + ' #t28-res').val(t28);
    $(componentSelector + ' #s28-res').val(s28);
    $(componentSelector + ' #t68-res').val(t68);
    $(componentSelector + ' #s68-res').val(s68);
  }

  let calculateDAS28 = (t28, s28, esr, pgh) => {

    let x = 0.56 * Math.sqrt(t28) + 0.28 * Math.sqrt(s28) + 0.7 * Math.log(esr) + 0.014 * pgh;
    return (x > 0 ? Math.round(x * 100) / 100 : 0);
  }

  let calculateDAS28CRP = (t28, s28, crp, pgh) => {
    let x = 0.56 * Math.sqrt(t28) + 0.28 * Math.sqrt(s28) + 0.36 * Math.log(crp + 1) + 0.014 * pgh + 0.96;
    return (x > 0 ? Math.round(x * 100) / 100 : 0);
  }

  let calculate = () => {

    let t28 = $(componentSelector + ' #t28-res').val();
    let s28 = $(componentSelector + ' #s28-res').val();

    let t68 = $(componentSelector + ' #t68-res').val();
    let s68 = $(componentSelector + ' #s68-res').val();

    let esr = $(componentSelector + " #slider-esr" + componentId + " .noUi-tooltip").html();
    let crp = $(componentSelector + " #slider-crp" + componentId + " .noUi-tooltip").html();
    let pgh = $(componentSelector + " #slider-pgh" + componentId + " .noUi-tooltip").html();

    let das28 = calculateDAS28(t28, s28, esr, pgh);
    let das28crp = calculateDAS28CRP(t28, s28, crp, pgh);

    $(componentSelector + ' #das28-res').val(das28);
    $(componentSelector + ' #das28crp-res').val(das28crp);
  }

  return {
    initHomer,
  }
}