<template>
  <div class="wrapper">
    <header class="bg-light">
      <div class="container-fluid d-flex justify-space-between">
        <div class="d-flex pt-2 pb-2">
          <strong class="fs-3">EMR</strong>
          <export-view name="ClinicalNotes.pdf" ref="exportView" :exportData="exportData"
            :handleSave="handleSave"></export-view>
        </div>
        <button type="button" class="my-2 btn btn-primary btn-custom ml-3" data-bs-toggle="modal"
          data-bs-target="#uploadFile">
          <img src="@/assets/icons/upload.svg" alt="Upload" />
        </button>
      </div>
    </header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <div id="editorjs" />
        </div>
        <div class="col-3">
          <content-div-component :currentTab="currentTab"></content-div-component>
        </div>
      </div>
    </div>
  </div>
  <!-- File upload modal -->
  <div>
    <div class="modal" tabindex="-1" role="dialog" ref="uploadModal" aria-labelledby="printLabel" aria-hidden="true"
      id="uploadFile">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload Excel File</h5>
            <button type="button" :disabled="isDataLoading" class="btn-close" @click="closePopup" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- File upload - read csv file -->
            <input ref="fileInput" type="file" accept=".xlsx" @change="handleFileChange" class="form-control" />
            <p v-if="noFileUploaded">Upload the Excel file to proceed.</p>
            <p v-else>File uploaded successfully, please click on close.</p>
          </div>
          <div class="modal-footer">
            <button type="button" :disabled="isDataLoading" class="btn btn-primary btn-custom" @click="closePopup">
              <div class="d-flex align-items-center justify-content-center">
                <div v-if="isDataLoading" class="loader loader-static mr-2"></div>
                <span v-else>Close</span>
              </div>
            </button>
            
          </div>
        </div>
      </div>
    </div>
  </div>

  <print-modals :exportData="exportData" :handlePrint="handlePrint"></print-modals>
</template>
<script setup>
import { useStore } from "vuex";
import * as Papa from "papaparse";
import * as XLSX from "xlsx";
import { ref, onMounted, computed, nextTick, defineComponent } from "vue";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Image from "./image";
import History from "./History/historyAutocomplete";
import Diagnosis from "./Diagnosis/diagnosisAutocomplete";
import TreatmentHistory from "./TreatmentHistory/treatmentHistory";
import PastHistory from "./PastHistory/pastHistory";
import LifeStyle from "./LifeStyle/lifestyle-personal-history";
import HaqScore from "./HaqScore/haqScore";
import DasCalculator from "./DASCalculator/dasCalculator";
import CrpCalculator from "./CRPCalculator/crpCalculator";
import Prescription from "./Prescription/prescription";
import DocumentUpload from "./DocumentUpload/documentUpload";
import AutoCompleteCommonModule from "./AutoCompleteCommonModule/autoCompleteCommonModule";
import ShortHistory from "./ShortHistory/shortHistory";
import ExportView from "@/components/Print/printFormat.vue";
import tabsData from '../data.json';
import PrintModals from "@/components/Print/PrintModals.vue"
import ContentDivComponent from "./ContentDivComponent.vue";
// console.log('ContentDivComponent: ', ContentDivComponent);
console.log('ExportView: ', ExportView);

defineComponent({
  "export-view": ExportView,
  "print-modals": PrintModals,
  "content-div-component": ContentDivComponent
})
const store = useStore();
const currentTab = ref(null)
const showUploadPopup = ref(false);
const uploadModal = ref(null);
const fileInput = ref(null);
const modal = ref(null);
const csvToJsonData = ref(null);
const noFileUploaded = ref(true);
let exportView = ref();
let exportData = ref(null);
const csvToJson = computed(() => {
  return store.getters.getCsvToJsonData;
})
let getTabValues = ref([])
let editor = ref(null);
let isDataLoading = ref(false);

const loadEditor = async () => {
  if (editor.value) {
    editor.value.destroy();
  }
  await nextTick();
  const tools = handleToolbars();
  console.log("tools: ", tools);
  editor.value = new EditorJS({
    holder: "editorjs",
    tools: {
      ...tools
    },
    onChange: (api, event) => {
      if (event.detail?.target.name != 'paragraph') {
        currentTab.value = capitalizeFirstLetter(event.detail?.target?.name)
        if (currentTab.value && !getTabValues.value.includes(currentTab.value)) {
          currentTab.value = 'All'
        }
        // console.log('currentTab.value: ', currentTab.value);
      }
    }
  });
  editor.value.isReady
    .then(() => {
      console.log("Editor.js is ready to work!");
      /** Do anything you need after editor initialization */
    })
    .catch((reason) => {
      console.log(`Editor.js initialization failed because of ${reason}`);
    });

}
// // Get the current block selection
// const blockSelection = editor.selection.block;

// // Get the current tool name
// const currentTool = blockSelection?.currentBlock?.tool;

// // Log the current tool name
// console.log('Current selected tool:', currentTool);


onMounted(() => {
  modal.value = new bootstrap.Modal(uploadModal.value);
  currentTab.value = "All"
  getTabValues.value = tabsData.tabs.map(tab => tab.name)
  loadEditor()
});


const closePopup = () => {
  fileInput.value.value = null
  noFileUploaded.value = true
  modal.value.hide();
  // Listen for the modal fully hidden event
  modal.value._element.addEventListener('hidden.bs.modal', () => {
    fileInput.value.value = null;
    noFileUploaded.value = true;
    showUploadPopup.value = false;
  });
};

const handleToolbars = () => {
  let obj = {};
  let typeObj = {
    autocomplete: {
      class: AutoCompleteCommonModule,
    },
    widget: {
      "t-history": {
        class: TreatmentHistory,
      },
      prescription: {
        class: Prescription,
      },
      "short-history": {
        class: ShortHistory,
      },
      "past-history": {
        class: PastHistory,
      },
      "life-style-personal-history": {
        class: LifeStyle,
      },
      "haq-score": {
        class: HaqScore,
      },
      "das28-calc": {
        class: DasCalculator,
      },
      "das28-crp-calc": {
        class: CrpCalculator,
      },
      'doc-upload': {
        class: DocumentUpload
      }
    },
  };
  Object.keys(csvToJson.value).forEach((el) => {
    if (
      csvToJson.value[el].type == "widget" &&
      typeObj[csvToJson.value[el].type][csvToJson.value[el]?.data[0]]
    ) {
      obj[el] = typeObj[csvToJson.value[el].type][csvToJson.value[el]?.data[0]];
      obj[el] = { ...obj[el], config: { name: el }, title: csvToJson.value[el].name };
    } else if (csvToJson.value[el].type == "autocomplete") {
      obj[el] = typeObj[csvToJson.value[el].type];
      obj[el] = { ...obj[el], config: { name: el }, name: csvToJson.value[el].name };
    }
  });
  return obj;
};

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    readFile(file);
    setTimeout(() => { loadEditor() }, 100);
  } else {
    // Show the Bootstrap modal if no file selected
    showUploadPopup.value = true;
  }
}

// Read file from input
const readFile = (file) => {
  const reader = new FileReader();
  isDataLoading.value = true
  reader.onload = (e) => {
    const data = e.target.result;
    if (file.name.endsWith(".csv")) {
      parseCSV(data);
    } else if (file.name.endsWith(".xlsx")) {
      parseXLSX(data);
    } else {
      console.error("Unsupported file format");
    }
  };

  reader.readAsBinaryString(file);
};

// parse csv file and read it's data
const parseCSV = (data) => {
  Papa.parse(data, {
    complete: (result) => {
      const jsonResult = result.data?.length ? result.data.slice(1) : [];
      if (typeof jsonResult == "object") {
        if (jsonResult[0].hasOwnProperty("Label")) {
          const formattedData = jsonResult.map((el) => ({
            name: el["Label"],
            type: el["type"],
            data: el["Sample data"],
          }));
          combinedCsvData(formattedData);
        } else if (jsonResult[0].hasOwnProperty("History-Medicine")) {
          const formattedData = [...jsonResult];
          store.commit("setMedicineData", formattedData);
        }
      }
    },
    header: true,
  });
};

// parse excel file data and convert it to json
const parseXLSX = (data) => {
  const workbook = XLSX.read(data, { type: "binary" });
  // sheet 1
  const sheetName1 = workbook.SheetNames[0]; // index-0 for sheet 1
  const sheet1 = workbook.Sheets[sheetName1];
  const jsonResultOfSheet1 = XLSX.utils.sheet_to_json(sheet1, { raw: true });
  console.log("sheet1: ", jsonResultOfSheet1);
  handleSheetData(jsonResultOfSheet1, "sheet1");

  // sheet 2
  const sheetName2 = workbook.SheetNames[1]; // index-1 for sheet 2
  const sheet2 = workbook.Sheets[sheetName2];
  const jsonResultOfSheet2 = XLSX.utils.sheet_to_json(sheet2, { raw: true });
  handleSheetData(jsonResultOfSheet2, "sheet2");
};

// convert excel data to json
const handleSheetData = (result, sheet = "") => {
  const jsonResult = result;
  if (typeof jsonResult == "object") {
    if (sheet == "sheet1" && jsonResult[0].hasOwnProperty("Label")) {
      const formattedData = jsonResult.map((el) => ({
        name: el["Label"],
        type: el["type"],
        data: el["Sample data"],
      }));
      combinedCsvData(formattedData);
    } else if (sheet == "sheet2" && jsonResult[0].hasOwnProperty("History-Medicine")) {
      const formattedData = [...jsonResult];
      store.commit("setMedicineData", formattedData);
    }
  }
};

// combine csv generated data to prepare dynamic json payload
const combinedCsvData = (array) => {
  const combinedMap = new Map();
  array.forEach((obj) => {
    const key = `${obj.name}-${obj.type}`;
    if (!combinedMap.has(key)) {
      combinedMap.set(key, { name: obj.name, type: obj.type, data: [obj.data] });
    } else {
      const existingObj = combinedMap.get(key);
      existingObj.data.push(obj.data);
    }
  });

  const combinedData = [...combinedMap.values()];
  let resultObject = {};
  combinedData.forEach((obj) => {
    const key = obj.name.toLowerCase();
    resultObject[key] = {
      type: obj.type,
      data: obj.data,
      name: obj.name,
    };
  });
  csvToJsonData.value = resultObject;
  // Store the csv data in local storage
  console.log("csvToJsonData.value: ", csvToJsonData.value);
  isDataLoading.value = false
  noFileUploaded.value = false;
  store.commit("setCsvToJsonData", csvToJsonData.value);
};

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
let handleSave = (e) => {
  editor?.value
    ?.save()
    .then((outputData) => {
      console.log("Article data: ", outputData);
      exportData.value = outputData;
    })
    .catch((error) => {
      console.log("Saving failed: ", error);
    });
};
let handlePrint = () => {
  exportView.value?.downloadFile();
}

</script>