<template>
  <div :id="componentId" class="pt-5" style="cursor: text">
    <p class="mb-4 mt-3 mr-5"><span class="yellow_chip">History</span></p>
    <span
      :id="componentId + 'editableSpan'"
      contenteditable="true"
      data-name="firstSpan"
      class="editable-span"
    >
      <input
        :id="componentId + 'placeholderSpan'"
        placeholder="Type here..."
        class="custom-disabled"
        type="text"
        readonly
        @click="focusOnSpan"
        @focus="focusOnSpan"
      />
    </span>
    <span :id="componentId + 'suggestionSpan'" class="autocomplete-span"></span>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, watch, computed } from "vue";
import { useStore } from 'vuex'
const store = useStore();
import jsonData from "../../autocomplete.json";
console.log('store: ', store);
const csvToJsonData = computed(() => {
  return store.getters.getCsvToJsonData;
})
console.log('csvToJsonData: ', csvToJsonData.value.history);
const editableSpan = ref(null);
const suggestionSpan = ref(null);
const placeholderSpan = ref(null);
const suggestedText = ref(null);
const historyJson = csvToJsonData.value?.history?.data?.length ? csvToJsonData.value?.history?.data : [];
console.log('historyJson: ', historyJson);
const newLineFlag = ref(null);
const autocompleteText = ref(null);


let propsData = defineProps({
  componentId: {
    default: "",
  },
  api: {},
});

onMounted(() => {
  editableSpan.value = document.getElementById(propsData.componentId + "editableSpan");
  suggestionSpan.value = document.getElementById(
    propsData.componentId + "suggestionSpan"
  );
  placeholderSpan.value = document.getElementById(
    propsData.componentId + "placeholderSpan"
  );
  focusOnSpan();
  document.addEventListener("keydown", null);
});

watch(editableSpan, (newValue, oldValue) => {
  editableSpan.value.addEventListener("focus", makeThisEditable);
  editableSpan.value.addEventListener("keydown", handleKeyDown);
  editableSpan.value.addEventListener("input", onContentChange);
  editableSpan.value.addEventListener("blur", onBlur);
});
/**
 * For make the editable span focussed
 */
const focusOnSpan = () => {
  setTimeout(() => {
    editableSpan.value.focus();
  }, 0);
};

function onBlur(event) {
  removeSuggestion();
}
/**
 * Input Click event
 */
function onClick() {
  editableSpan.value.focus();
}

/**
 * Called when editable span is focused
 * @param {*} event
 */
function onFocus(event) {
  // console.log("propsData",event.target)
}

/**
 * Called when the keyDown event on editable Span
 * @param {object} event
 */
function handleKeyDown(event) {
  let editableSpanDiv = editableSpan.value;
  let innerSpan = editableSpan.value.querySelector("input");
  if (event.key?.length === 1 && innerSpan) {
    editableSpanDiv.removeChild(innerSpan);
  }
  if (event.key === "/") {
    event.stopPropagation();
  }
  if (event.key === "Enter") {
    removeSuggestion();
    changeEditableSpanLocation();
    event.preventDefault();
    event.stopPropagation();
    // newLineFlag.value = true
  }
  console.log("event: ", event);
  if (event.key == "Tab" && suggestedText.value) {
    handleAutoSuggestionSelected(event, editableSpanDiv);
  }
}

/**
 * Handles auto inserting suggestion text when user selects Tab key
 * @param {Event} event Keyboard keydown event
 * @param {Node} editableSpanDiv DOM node object of editable span element
 */
let handleAutoSuggestionSelected = (event, editableSpanDiv) => {
  event.preventDefault();
  event.stopPropagation();
  editableSpanDiv.focus();
  suggestionSpan.value.innerText = "";
  editableSpanDiv.innerHTML += (autocompleteText.value || "") + "&nbsp";
  editableSpanDiv.blur();
  const range = document.createRange();
  const selection = window.getSelection();
  const lastChild = editableSpanDiv.lastChild;
  if (lastChild) {
    range.setStart(lastChild, lastChild.textContent.length);
    range.setEnd(lastChild, lastChild.textContent.length);
  }
  selection.removeAllRanges();
  setTimeout(() => {
    selection.addRange(range);
    removeSuggestion();
    editableSpanDiv.focus();
  }, 0);
};

/**
 * Handles changing form of selected span to editable for editing use-case
 * @param {Event} e User event object
 */
let makeThisEditable = (e) => {
  removeEditableSpanAttributes();

  e.target.setAttribute("id", propsData.componentId + "editableSpan");
  e.target.setAttribute("contenteditable", true);
  // Change Span value
  editableSpan.value = document.getElementById(propsData.componentId + "editableSpan");

  let suggestionSpanData = suggestionSpan.value;
  suggestionSpan.value.remove();

  editableSpan.value.parentNode.insertBefore(
    suggestionSpanData,
    editableSpan.value.nextElementSibling
  );

  suggestionSpan.value = document.getElementById(
    propsData.componentId + "suggestionSpan"
  );
  focusOnSpan();
};

/**
 * Handles removing attributes of editable span element to normal span
 */
let removeEditableSpanAttributes = () => {
  if (!editableSpan.value) {
    editableSpan.value = document.getElementById(propsData.componentId + "editableSpan");
  }
  // ============>>>>Remove Editable Span Attributed<<<<<================ //
  editableSpan.value.removeAttribute("id");
  editableSpan.value.addEventListener("focus", makeThisEditable);
  // ============>>>>Remove Editable Span Attributed<<<<<================ //
};

function placeCaretAfterNode(node) {
  setTimeout(() => {
    if (typeof window.getSelection != "undefined") {
      console.log("placeCaretAfterNode: ");
      let range = document.createRange();
      range.setStartAfter(node);
      range.collapse(true);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, 0);
}

/**
 * Handles adding new editable span at last index
 */
let changeEditableSpanLocation = () => {
  return new Promise((resolve) => {
    removeEditableSpanAttributes();
    let span = document.createElement("span");
    let br = document.createElement("br");

    span.setAttribute("id", propsData.componentId + "editableSpan");
    span.setAttribute("contenteditable", true);
    span.style.display = "inline-block";
    span.style.maxWidth = "100%";
    span.classList.add("editable-span");

    span.onblur = onBlur;
    editableSpan.value.parentNode.insertBefore(span, suggestionSpan.value);
    // Change Span value
    editableSpan.value = span;
    placeCaretAfterNode(document.getElementById(propsData.componentId + "editableSpan"));

    editableSpan.value.addEventListener("focus", makeThisEditable);
    editableSpan.value.addEventListener("click", makeThisEditable);
    setTimeout(() => {
      editableSpan.value.parentNode.insertBefore(br, editableSpan.value);
    }, 0);
    setTimeout(() => {
      focusOnSpan();
      resolve();
    }, 100);
  });
};

/**
 * Handles opening EditorJs toolbox manually
 */
let openToolBox = () => {
  setTimeout(() => {
    propsData.api?.toolbar?.open();
    propsData.api?.toolbar?.toggleToolbox();
  }, 50);
};

/**
 * Called when the input event of editable Span
 * @param {object} event
 */
function onContentChange(event) {
  // console.log(event.target)
  if (newLineFlag.value) {
    let nextElement = editableSpan.value.nextElementSibling;
    if (nextElement == suggestionSpan.value) {
      nextElement.remove();
    }
    let innerBR = editableSpan.value.querySelector("br");
    const brCount = editableSpan.value.querySelectorAll("br").length;

    console.log("Number of <br> tags:", brCount);
    console.log("innerBR: ", innerBR);
    // let targetElement = innerBR
    const referenceElement = innerBR.nextElementSibling;
    console.log("referenceElement: ", referenceElement);
    const newSpan = document.createElement("span");
    newSpan.setAttribute("id", propsData.componentId + "suggestionSpan");
    newSpan.classList.add("autocomplete-span");
    referenceElement.parentNode.insertBefore(newSpan, referenceElement);
    // innerBR.insertAdjacentElement('afterend', newSpan);
    suggestionSpan.value = newSpan;
    newLineFlag.value = false;
    console.log(innerBR);
  } else if (!suggestionSpan.value) {
    const newSpan = document.createElement("span");
    newSpan.setAttribute("id", propsData.componentId + "suggestionSpan");
    newSpan.classList.add("autocomplete-span");
    editableSpan.value.insertAdjacentElement("afterend", newSpan);
    suggestionSpan.value = newSpan;
    newSpan.contentEditable = true;
  }

  // *************************************************************************** //
  // =================================== Start =================================== //
  // *************************************************************************** //

  let htmlValRaw = editableSpan.value.innerHTML.slice();
  let htmlVal =
    htmlValRaw.lastIndexOf("<br>") !== -1
      ? htmlValRaw.slice(0, parseInt(htmlValRaw.lastIndexOf("<br>")))
      : htmlValRaw.slice();
  console.log(
    'parseInt(htmlValRaw.lastIndexOf("<input")): ',
    parseInt(htmlVal.lastIndexOf("<input"))
  );
  htmlVal =
    htmlVal.lastIndexOf("<input") !== -1
      ? htmlVal.slice(0, parseInt(htmlVal.lastIndexOf("<input")))
      : htmlVal.slice();
  console.log("htmlValRaw: ", htmlValRaw);
  console.log("htmlVal: ", htmlVal);

  let inputValue = htmlVal
    .replaceAll("<br>", " ")
    .replaceAll("&nbsp;", " ")
    .toLowerCase();
  console.log("inputValue: ", inputValue);
  // *************************************************************************** //
  // =================================== End =================================== //
  // *************************************************************************** //

  /**
   * If new line and input value is "/" then open Toolbox
   */
  if (inputValue.trim() == "/") {
    console.log("inputValue.trim(): ", inputValue.trim());
    openToolBox();

    setTimeout(() => {
      editableSpan.value.innerHTML = editableSpan.value.innerHTML.replace("/", "");
    }, 50);
    return false;
  }

  let isEndingWithSpace =
    inputValue && inputValue.trimStart().length !== inputValue.trim().length;
  console.log("isEndingWithSpace: ", isEndingWithSpace);

  let enteredString =
    inputValue
      .trimStart()
      .split(" ")
      ?.filter((el) => el && el !== "")
      .slice(-1)?.[0] || "";
  console.log("enteredString: ", enteredString);

  suggestedText.value = "";
  if (inputValue.trim() !== "") {
    if (!isEndingWithSpace && enteredString.trim()) {
      suggestedText.value = historyJson.find((suggestion) => {
        if (suggestion.toLowerCase().startsWith(enteredString)) {
          return true;
        }
      });
    }
    console.log("111 suggestedText.value: ", suggestedText.value);
    if (!suggestedText.value && enteredString.trim()) {
      enteredString = enteredString.trim();
      let textContent = historyJson.find((suggestion) => {
        if (suggestion.toLowerCase().includes(enteredString)) {
          return true;
        }
      });

      if (textContent) {
        console.log("textContent: ", textContent);

        let shouldIncludeString = textContent
          .slice(0, textContent.indexOf(enteredString))
          .split(enteredString + " ")
          .join(" ");

        console.log("shouldIncludeString: ", shouldIncludeString);

        if (
          inputValue
            .toLocaleLowerCase()
            .slice(
              0,
              inputValue.lastIndexOf(enteredString + (isEndingWithSpace ? " " : ""))
            )
            .slice(-shouldIncludeString.trim().length - 1)
            .includes(shouldIncludeString.toLocaleLowerCase())
        ) {
          console.warn(
            "textContent slice",
            textContent.slice(textContent.indexOf(" " + enteredString))
          );
          let suggestedString = textContent
            .slice(textContent.indexOf(" " + enteredString) + enteredString.length + 1)
            .split(" " + enteredString + " ")
            .join(" ");
          console.log("suggestedString: ", suggestedString);
          let isEndingWithSame =
            inputValue.trim().lastIndexOf(suggestedString.trim()) ==
            inputValue.trim().length - suggestedString.trim().length - 1;
          console.log("isEndingWithSame: ", isEndingWithSame);
          suggestedText.value = isEndingWithSame ? "" : suggestedString;
        }
      } else {
        removeSuggestion();
      }
    }
    console.log("suggestedText.value: ", suggestedText.value);

    if (suggestedText.value) {
      autocompleteText.value = suggestedText.value?.replace(
        new RegExp(`^${enteredString.trim()}`, "i"),
        ""
      );
      console.log("autocompleteText.value: ", autocompleteText.value);
      suggestionSpan.value.innerHTML = autocompleteText.value;
    } else {
      removeSuggestion();
    }
  } else {
    removeSuggestion();
    if (editableSpan.value.getAttribute("data-name")) {
      editableSpan.value.appendChild(placeholderSpan.value);
    }
  }
}
/**
 * Remove the suggestion from the suggestion span
 */
function removeSuggestion() {
  if (suggestionSpan.value?.innerHTML) {
    suggestionSpan.value.innerHTML = "";
    suggestedText.value = "";
  }
  autocompleteText.value = null;
}
</script>
