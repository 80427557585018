<template>
    <div class="wrapper" :id="'shortHistory' + componentId">
        <!---------Short History---------->
        <div class="mt-5">
            <p><span class="yellow_chip">Short History</span></p>
            <div class="editor_text mb-5">
                <div class="row-cols-auto g-3 align-items-center ">
                    Early Morning Stiffness
                    <input type="text" class="form-control d-inline " :id="'shortHistoryDiv' + componentId" /> hrs.
                </div>
            </div>

            <ul class="list-group colcount2">
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id='firstCheckbox'>
                    <label class="form-check-label" for="firstCheckbox">Subcutaneous nodules</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="secondCheckbox">
                    <label class="form-check-label" for="secondCheckbox">Pedal Edema</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="thirdCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Non Healing ulcer</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Occipital Headache</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Dry Eyes/Dry Mouth</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Dry Cough, DOE</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Palpable purpura</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Mononeuritis</label>
                </li>
                <li class="list-group-item">
                    <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox">
                    <label class="form-check-label" for="thirdCheckbox">Paraesthesias</label>
                </li>
            </ul>

        </div>
        <!---------Short History ends---------->
    </div>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
let propsData = defineProps({
    componentId: {
        default: "",
    },
});
onMounted(() => {
    document.getElementById('shortHistoryDiv' + propsData.componentId).focus()
    $(document).ready(function () {
        let selectedIndex = 1; // To keep track of the selected index
        function handleArrowKeys(event) {
            const rows = $('#shortHistory' + propsData.componentId).find('input');
            //   console.log('rows: ', rows);
            if (event.key === "ArrowDown" && selectedIndex < rows.length) {
                selectedIndex++;
            } else if (event.key === "ArrowUp" && selectedIndex > 0) {
                selectedIndex--;
            }
            // console.log('selectedIndex: ', selectedIndex);

            // Update the selected row
            //   console.log('rows: ', rows);
            rows[selectedIndex - 1]?.focus()
            // rows.each((index, row) => {
            // });
        }
        document.getElementById('shortHistory' + propsData.componentId).addEventListener('keydown', function (e) {
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                e.preventDefault()
                e.stopPropagation()
                handleArrowKeys(e)
            }
        });
    });
})
</script>