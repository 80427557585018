export default class SimpleImage {
  static get toolbox() {
    return {
      title: 'Image',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
    };
  }

  constructor({ data, config, api }) {
    this.api = api;
    this.data = {
      url: data && data.url ? data.url : '',
    };
  }

  render() {
    const container = document.createElement('div');
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', () => this.onFileSelect(input, container));

    const previewImage = document.createElement('img');
    previewImage.style.display = 'none';

    container.appendChild(input);
    container.appendChild(previewImage);


    return container;
  }

  onFileSelect(input, container) {
    const file = input.files[0];
    
    if (file) {
      this.previewImage(file, container,input);
      // this.uploadFile(file);
    }
  }
  previewImage(file, container,input) {
    const previewImage = container.querySelector('img');
    const reader = new FileReader();

    reader.onload = function (e) {
      previewImage.src = e.target.result;
      previewImage.style.display = 'block';
      previewImage.style.width = '50%';
      previewImage.style.height = '50%';

    }
    container.removeChild(input);
    this.data.url = previewImage.src
    reader.readAsDataURL(file);

  }
  
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    fetch('/your-upload-endpoint', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        this.data.url = data.url;
        this.api.blocks.updateBlock(this.data);
      })
      .catch(error => console.error('File upload error:', error));
  }

  save() {
    return this.data;
  }
}
