<template>
  <div class="wrapper pt-5" :id="'pastHistory' + componentId">
    <div class="container-fluid pl-0">
      <!---------Past History ---------->
      <div>
        <p><span class="yellow_chip">Past History</span></p>
        <ul class="list-group colcount2">
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" :id="'firstCheckbox' + componentId" />
            <label class="form-check-label" for="firstCheckbox">Diabetes mellitus</label>
            <p class="my-3 ms-3">
              Onset
              <input type="text" class="form-control d-inline" />
              Insulin
              <input type="text" class="form-control d-inline" /> Oral
              hypoglycaemic
              <input type="text" class="form-control d-inline" />
            </p>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" tabindex="0"
              :id="'secondCheckbox' + componentId" />
            <label class="form-check-label" for="secondCheckbox">Hypertension</label>

            <p class="my-3 ms-3">
              Onset
              <input type="text" class="form-control d-inline" />
              Drugs
              <input type="text" class="form-control d-inline" />
            </p>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="thirdCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Hypothyroidism</label>

            <p class="my-3 ms-3">
              Onset
              <input type="text" class="form-control d-inline" />
              Drugs
              <input type="text" class="form-control d-inline" />
            </p>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">GI Bleed</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Chronic liver disease</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Stroke</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Tuberculosis</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Jaundice</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Angina</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Asthma</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">Treated adequately</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">MI</label>
          </li>
          <li class="list-group-item">
            <input class="form-check-input me-1" type="checkbox" value="" id="fourthCheckbox" />
            <label class="form-check-label" for="thirdCheckbox">COPD</label>
          </li>
        </ul>
      </div>
      <!---------Past History Ends---------->
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
let propsData = defineProps({
  componentId: {
    default: "",
  },
});
onMounted(() => {

  $(document).ready(function () {
    let selectedIndex = 1; // To keep track of the selected index

    function handleArrowKeys(event) {
      const rows = $('#pastHistory' + propsData.componentId).find('input');
      // console.log('rows: ', rows);
      if (event.key === "ArrowDown" && selectedIndex < rows.length) {
        selectedIndex++;
      } else if (event.key === "ArrowUp" && selectedIndex > 0) {
        selectedIndex--;
      }
      // console.log('selectedIndex: ', selectedIndex);

      // Update the selected row
      // console.log('rows: ', rows);
      rows[selectedIndex-1]?.focus()
      // rows.each((index, row) => {
      // });
    }
    document.getElementById('pastHistory' + propsData.componentId).addEventListener('keydown', function (e) {
      if(e.key === "ArrowDown" || e.key === "ArrowUp"){
        e.preventDefault()
        e.stopPropagation()
        handleArrowKeys(e)
      }
    });
  });
  document.getElementById('firstCheckbox' + propsData.componentId).focus()
})
</script>