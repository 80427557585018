<template>
  <div class="wrapper pt-5">
    <div class="container-fluid pl-0">
      <div class="editor_area pl-0">
        <!--------DAS28-CRP Calculator----------->
        <div>
          <p><span class="yellow_chip">DAS28-CRP Calculator</span></p>
          <div class="mb-3 d-flex align-items-center">
            <label>Tender joint count (0 - 28)</label>
            <input
              type="number"
              class="form-control"
              min="0"
              max="28"
              :id="'tjs' + propsData.componentId"
              name="TJs"
            />
          </div>
          <div class="mb-3 d-flex align-items-center">
            <label>Swollen joint count (0 - 28)</label>
            <input
              type="number"
              class="form-control"
              min="0"
              max="28"
              :id="'sjc' + propsData.componentId"
              name="SJc"
            />
          </div>
          <div class="mb-3 d-flex align-items-center">
            <label>C-reactive protein (mg/L)</label>
            <input
              type="number"
              class="form-control"
              min="0"
              max="28"
              :id="'crp' + propsData.componentId"
              name="CRP"
            />
          </div>
          <div class="mb-3 d-flex align-items-center">
            <label>Global Health (0 - 100)*</label>
            <span>
              <input
                type="number"
                class="form-control"
                min="0"
                max="28"
                :id="'gh' + propsData.componentId"
                name="GH"
              />
              <small class="text-light"
                >*Scale where 0 is very good and 100 is very bad.</small
              >
            </span>
          </div>
          <div class="mb-3 d-flex">
            <button :id="'calcDAS' + propsData.componentId" class="btn btn-primary me-2">Calculate</button>
            <button :id="'reset'+ propsData.componentId " class="btn btn-secondary">Reset</button>
          </div>

          <div class="fs-4 bg-light p-3 mb-3 rounded">
            DAS28-CRP = <span id="dascrp"></span>
          </div>
        </div>
        <!--------DAS28-CRP Calculator ENDS----------->
      </div>
    </div>
  </div>
  <!--wrapper-->
</template>
<script setup>
  import { ref, onMounted, defineProps, watch } from "vue";
  let propsData = defineProps({
    componentId: {
      default: "",
    },
  });
  let tjsDiv = ref(null);
  onMounted(() => {
    tjsDiv.value = document.getElementById("tjs" + propsData.componentId);
    tjsDiv.value.focus()
  })
  $(document).ready(function () {
    $(".myselection").on("change", function () {
      var demovalue = $(this).val();
      $("div.myDiv").hide();
      $("#show" + demovalue).show();
    });
    function sqrt(n) {
      return Math.sqrt(n);
    }
    function ln(n) {
      return Math.log(n);
    }

    $("#calcDAS" + propsData.componentId).click(function () {
      var tjs = $("#tjs" + propsData.componentId).val();
      var sjc = $("#sjc" +  propsData.componentId).val();
      var crp = $("#crp" + propsData.componentId).val();
      var gh = $("#gh" + propsData.componentId).val();

      console.log(tjs);
      console.log(sjc);
      console.log(crp);
      console.log(gh);

      var das28 =
        0.56 * Math.sqrt(tjs) +
        0.28 * Math.sqrt(sjc) +
        0.36 * Math.log(crp + 1) +
        0.014 * gh +
        0.96;
      $("#dascrp").html(das28);
    });

    $("#reset" + propsData.componentId).click(function () {
      tjsDiv.value.val("");
      $("#sjc").val("");
      $("#crp").val("");
      $("#gh").val("");
    });
  });
</script>