<template>
  <button
    type="button"
    class="btn btn-primary btn-custom ml-3"
    data-bs-toggle="modal"
    data-bs-target="#print"
    @click="handleSave"
  >
  <img src="@/assets/icons/print.svg" alt="Print" />
  </button>
  
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  watch,
  computed,
  reactive,
  defineExpose,
} from "vue";
import html2pdf from "html2pdf.js";
import { useStore } from "vuex";
let propsData = defineProps([
  "dom",
  "name",
  "multiple",
  "exendSize",
  "setSizeA3",
  "setSizeA1",
  "landscape",
  "increasedQuality",
  "scale",
  "margin",
  "handleSave",
]);


let id = ref("exportClinicalNotes");

let downloadFile = () => {
  // store.commit("setLoading", true);
  const tab1 = document.querySelector("#" + id.value);
  console.log("tab1: ", tab1);
  let tab2 = document.querySelector("#" + id.value);
  // tab2 = $(tab2).addClass("d-block");
  let opt = {
    margin: [5, 5],
    top: 0,
    filename: propsData.name,
    image: { type: "jpeg", quality: 1 },
    html2canvas: { dpi: 192, scale: 2 },
    jsPDF: { unit: "mm", format: "A4", orientation: "P", compress: true },
    pagebreak: { avoid: "tr,h6,li,p,label,img", after: ".html2pdf__page-break" },
  };
  if (propsData.margin) {
    opt.margin = propsData.margin;
  }
  if (propsData.scale) {
    opt.html2canvas.scale = propsData.scale;
  }
  if (propsData.landscape == "true") {
    opt.jsPDF = { unit: "mm", format: "A4", orientation: "L", compress: true };
  }
  if (propsData.exendSize == "true") {
    opt.jsPDF = { unit: "mm", format: "A2", orientation: "P", compress: true };
  }
  if (propsData.exendSize == "true" && propsData.landscape == "true") {
    opt.jsPDF = { unit: "mm", format: "A2", orientation: "L", compress: true };
  }
  if (propsData.setSizeA1 == "true") {
    opt.jsPDF = { unit: "mm", format: "A0", orientation: "L", compress: true };
  }
  if (propsData.setSizeA3 == "true") {
    opt.jsPDF = { unit: "mm", format: "A3", orientation: "P", compress: true };
  }
  if (propsData.increasedQuality == "true") {
    opt.html2canvas = { dpi: 192, scale: 1.5 };
  }
  setTimeout(() => {
    if (propsData.multiple == "true") {
      html2pdf().set(opt).from(tab2[0]).save();
      setTimeout(() => {
        // tab2 = $(tab2).removeClass("d-block");
      }, 5000);
    } else {
      html2pdf().from(tab1).set(opt).save();
      setTimeout(() => {
        // tab2 = $(tab2).removeClass("d-block");
      }, 1000);
    }
  }, 500);
};
defineExpose({ downloadFile });
</script>
