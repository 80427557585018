<template>
  <p class="mb-4 mt-3 mr-5"><span class="yellow_chip">Document Upload</span></p>
  <!--File Upload Modal-->
  <div id="drop-area" v-if="!isUpload">
    <label for="fileElem" class="d-flex flex-column" style="cursor: pointer">
      <span class="file-uoload-image">
        <img src="../../assets/icons/upload-file-icon.svg" alt="" />
      </span>
      <span class="drag-text">Drag and drop files or <span class="browse-text">Browse</span></span>
      <span class="supported-file">Supported formates: png, jpeg, jpg | File Size: Max 500KB</span>
    </label>
    <input type="file" id="fileElem" multiple @change="handleFiles" style="display: none" />
    <div id="file-grid" class="file-grid"></div>
    <button class="upload-btn" @click="uploadFiles()">Upload</button>
  </div>

  <!--Display Uploaded Files-->
  <div class="uploaded-files mt-5" v-else>
    <ul class="list-group">
      <li v-for="(file,index) in uploadedFiles" class="list-group-item d-flex justify-content-between align-items-center"  :key="file.id" :class="file.OCR ? 'progress-upload' : ''">
        <div v-if='file.OCR' class="progress-upload-fill" style="width: 64%"></div>
        <div class="d-flex">
          <div class="flex-shrink-0">
            <div class="file-up-icon">
              <img src="../../assets/icons/pdf-icon.png" alt="" class="pdf-icon-w" />
            </div>
          </div>
          <div class="flex-grow-1 ms-3">{{ file.name }}</div>
        </div>
        <div v-if="!file.OCR" class="upload-right">
          <div class="d-flex align-items-center">
            <span role="button" class="badge green-bg rounded-pill" @click="showOCR(index)">
              <img src="../../assets/icons/ocr.svg" alt="" class="me-2" />
              <span class="align-middle">OCR</span>
            </span>
            <div class="dropdown more-items ml-3">
              <button class="btn btn-link p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../../assets/icons/more.svg" alt="" />
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a class="dropdown-item" href="#">
                    <div class="d-flex align-items-center">
                      <div class="item-icon">
                        <img src="../../assets/icons/delete.svg" alt="" />
                      </div>
                      <span>Delete</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <div class="d-flex align-items-center">
                      <div class="item-icon">
                        <img src="../../assets/icons/download.svg" alt="" />
                      </div>
                      <span>Download</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <div class="d-flex align-items-center">
                      <div class="item-icon">
                        <img src="../../assets/icons/rename.svg" alt="" />
                      </div>
                      <span>Rename</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <div class="d-flex align-items-center">
                      <div class="item-icon">
                        <img src="../../assets/icons/comment.svg" alt="" />
                      </div>
                      <span>Comment</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <div class="d-flex align-items-center">
                      <div class="item-icon">
                        <img src="../../assets/icons/change-color.svg" alt="" />
                      </div>
                      <span>Change Color</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else class="upload-right">
          <div class="d-flex align-items-center">
            <span class="me-2 percentage"> 64% </span>
            <button class="btn btn-link p-0" @click="hideOCR(index)">
              <img src="../../assets/icons/close.svg" alt="" />
            </button>
          </div>
        </div>
      </li>
      <!-- <li v-if='previewOCR' class="list-group-item d-flex justify-content-between align-items-center progress-upload">
        <div class="progress-upload-fill" style="width: 64%"></div>
        <div class="d-flex">
          <div class="flex-shrink-0">
            <div class="file-up-icon">
              <img src="../assets/icons/pdf-icon.png" alt="" class="pdf-icon-w" />
            </div>
          </div>
          <div class="flex-grow-1 ms-3">{{ file }}</div>
        </div>
        <div class="upload-right">
          <div class="d-flex align-items-center">
            <span class="me-2 percentage"> 64% </span>
            <button class="btn btn-link p-0">
              <img src="../assets/icons/close.svg" alt="" />
            </button>
          </div>
        </div>
      </li> -->
    </ul>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
let dropArea = ref(null);
let isUpload = ref(false);
let uploadedFiles = ref([]);
let previewOCR = ref(null);

onMounted(() => {
  dropArea.value = document.getElementById("drop-area");

  dropArea.value.addEventListener("drop", handleDrop, false);
  ["dragenter", "dragover"].forEach((eventName) => {
    dropArea.value.addEventListener(eventName, highlight, false);
  });

  ["dragleave", "drop"].forEach((eventName) => {
    dropArea.value.addEventListener(eventName, unhighlight, false);
  });

  ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
    dropArea.value.addEventListener(eventName, preventDefaults, false);
    document.body.addEventListener(eventName, preventDefaults, false);
  });
});

// Inline JavaScript

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

function highlight(e) {
  dropArea.value.classList.add("highlight");
}

function unhighlight(e) {
  dropArea.value.classList.remove("highlight");
}

function handleDrop(e) {
  let dt = e.dataTransfer;
  let files = dt.files;
  handleFiles(files);
}

function handleFiles(event) {
  let files = [...event.target.files];
  files.forEach(previewFile);
}

function previewFile(file,index) {
  console.log(index);
  let fileGrid = document.getElementById("file-grid");

  let fileItem = document.createElement("div");
  fileItem.className = "file-item";

  let fileName = document.createElement("div");
  fileName.className = "file-name";
  fileName.textContent = file.name;

  let fileType = document.createElement("div");
  fileType.textContent = "Type: " + file.type;

  let fileSize = document.createElement("div");
  fileSize.textContent = "Size: " + file.size + " bytes";

  let removeBtn = document.createElement("span");
  removeBtn.className = "remove-btn";
  removeBtn.innerHTML = "&times;";
  removeBtn.onclick = removeFiles(fileGrid,fileItem)
  removeBtn.onclick = function () {
    fileGrid.removeChild(fileItem);
    let fileId = parseInt(fileItem.getAttribute("data-id"))
    uploadedFiles.value = uploadedFiles.value.filter((file) => file.id != fileId)
  };
  fileItem.setAttribute("data-id", index)
  fileItem.appendChild(removeBtn);
  fileItem.appendChild(fileName);
  fileItem.appendChild(fileType);
  fileItem.appendChild(fileSize);
  fileGrid.appendChild(fileItem);
  let _obj = {
    id: index,
    name : file.name,
    OCR : false
  }
  uploadedFiles.value.push(_obj);
}

function uploadFiles() {
  if(uploadedFiles.value.length){
    isUpload.value = true;
  }
}

function showOCR(index){
  previewOCR.value = true;
  uploadedFiles.value.forEach((file,i) => {
    if(index == i){
      file.OCR = true
    }
  })
}

function hideOCR(index){
  previewOCR.value = true;
  uploadedFiles.value.forEach((file,i) => {
    if(index == i){
      file.OCR = false
    }
  })
}

function removeFiles(){

}
</script>