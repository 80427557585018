<template>
  <div class="wrapper pt-5">
    <div class="container-fluid pl-0">
      <div class="editor_area pl-0">
        <!---------HAQ score---------->
        <div>
          <p><span class="yellow_chip">HAQ Score</span></p>
          <div class="tabular_data lesspadding">
            <table class="table table-bordered">
              <tbody>
                <!-- <tr>
                  <td><strong>Date</strong></td>
                  <td><input type="date" class="form-control" /></td>
                  <td><input type="date" class="form-control" /></td>
                  <td><input type="date" class="form-control" /></td>
                  <td><input type="date" class="form-control" /></td>
                  <td><input type="date" class="form-control" /></td>
                  <td><input type="date" class="form-control" /></td>
                </tr> -->
                <tr>
                  <td>
                    <strong>Dressing and grooming</strong>
                    <ol>
                      <li>
                        Dress yourself including tying shoelaces, doing buttons
                      </li>
                      <li>Shampoo/Wash your hair</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                      :id="'haqScore' + propsData.componentId"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Rising</strong>
                    <ol>
                      <li>Stand from an armless chair</li>
                      <li>Get in and out of bed</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Rising</strong>
                    <ol>
                      <li>Stand from an armless chair</li>
                      <li>Get in and out of bed</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Eating</strong>
                    <ol>
                      <li>Break a chappati</li>
                      <li>Lift a glass of water to your</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Eating</strong>
                    <ol>
                      <li>Break a chappati</li>
                      <li>Lift a glass of water to your mouth</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Walking</strong>
                    <ol>
                      <li>Walk on flat ground</li>
                      <li>Climb five steps</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Hygiene</strong>
                    <ol>
                      <li>Wash and dry your entire body</li>
                      <li>Take a bath</li>
                      <li>Get on and off the toilet</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Reach</strong>
                    <ol>
                      <li>Pick a container lying above the head level</li>
                      <li>Bend down to pick a cloth from ground</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Grip</strong>
                    <ol>
                      <li>Open hoosehold doors</li>
                      <li>open jars which have been previously opened</li>
                      <li>Turn tap on and off</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Activities</strong>
                    <ol>
                      <li>Go shopping</li>
                      <li>Get in and out of bus/car</li>
                      <li>Do household chores</li>
                    </ol>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="3"
                      length="1"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>Final HAQ Score</strong></td>
                  <td></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="7" class="bg-light">
                    0 = Without any Difficulty; 1 = With some difficulty; 2=With
                    much difficulty; 3=Unable to do / need someone else’s help /
                    Appliance. Add maximum score in each of the eight categories
                    and divide by 8
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!--tabular_data-->
        </div>
        <!---------HAQ score ends---------->
      </div>
    </div>
  </div>
  <!--wrapper-->
</template>
<script setup>
  import { ref, onMounted, defineProps, watch } from "vue";
  let propsData = defineProps({
    componentId: {
      default: "",
    },
  });
  onMounted(() => {
    document.getElementById('haqScore' + propsData.componentId).focus()
  })
</script>
