<template>
  <div class="wrapper pt-5">
    <div class="container-fluid pl=0">
      <p><span class="yellow_chip">Prescription</span></p>
      <div class="input-group">
        <label class="input-group-text" for="medicine-search"
          ><strong>Medicine Search</strong></label
        >
        <input
          class="form-control"
          type="text"
          id="medicine-search"
          v-model="searchString"
          placeholder="Search for a medicine"
        />
      </div>
      <div class="table-responsive">
        <table id="results-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Medicine Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              <th>Duration</th>
              <th>Total Medicines</th>
              <th>Instructions</th>
            </tr>
          </thead>
          <tbody>
            <!-- Results will be displayed here -->
          </tbody>
        </table>
      </div>

      <h4 class="mt-5">Selected Medicines</h4>
      <div class="table-responsive">
      <table id="selected-table">
        <thead>
          <tr>
            <th>Medicine Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>Duration</th>
            <th>Total Medicines</th>
            <th>Instructions</th>
          </tr>
        </thead>
        <tbody>
          <!-- Selected medicines will be displayed here -->
        </tbody>
      </table>
      </div>
    </div>
  </div>
  <!--wrapper-->
</template>

<style>
/* Add your custom CSS styles here */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 10px;
  text-align: left;
}
#results-table tr.selected td {
  background-color: #eeeeee;
}
.form-control:focus,
input:focus,
.fromhistory:focus {
  outline: black auto 1px;
  outline: 1px;
  border-color: 1px solid #86b7fe;
}
select {
  max-width: 150px;
}
option {
  border: solid 1px #dddddd;
  overflow-wrap: break-word !important;
  white-space: normal !important;
}
</style>
<script setup>
import { ref, onMounted, defineProps, watch, computed, reactive, defineExpose } from "vue";
import { useStore } from 'vuex'
const store = useStore();
const csvToJsonData = computed(() => {
  console.log('store.getters.getMedicineData: ', store.getters.getMedicineData);
  return store.getters.getMedicineData?.length ? store.getters.getMedicineData : [];
})
console.log('getMedicineData: ', csvToJsonData.value);
let propsData = defineProps({
  componentId: {
    default: "",
  },
});

let selectedMedicine = ref([]);

const searchInput = ref(null)
let searchString = ref(null)
const resultsTable = ref(null);
const selectedTable = ref(null);
const selectedMedicines = ref(null);
const medicineData = ref(null);

onMounted(() => {
  searchInput.value = document.getElementById("medicine-search");
  searchInput.value.focus();
  resultsTable.value = document.getElementById("results-table");
  selectedTable.value = document.getElementById("selected-table");
  selectedMedicines.value = new Set();
  

  document.addEventListener("keydown", function (event) {
    if (event.key === " ") {
      const selectedRow = resultsTable.value.querySelector("tr.selected");
      if (selectedRow) {
        const checkbox = selectedRow.querySelector("input.fromhistory");
        const medicineName = checkbox.dataset.name;
        const medicine = medicineData.value.find(
          (med) => med["Medicine Name"] === medicineName
        );

        // Toggle the selected state
        // toggleSelectedMedicine(medicine, selectedRow);
      }
    }
  });
  medicineData.value = csvToJsonData.value ? [...csvToJsonData.value] : []
  medicineData.value = medicineData.value.map((obj, index) => ({ ...obj, id: index + 1 }));

  console.log('medicineData.value: ', medicineData.value);

  // medicineData.value = [
  //   {
  //     "Medicine Name": "Methotrexate",
  //     Dosage: "15 mg",
  //     Frequency: "Once a week",
  //     Duration: "6 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food",
  //     "Hindi Instructions": "सप्ताह में एक बार, भोजन के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Hydroxychloroquine",
  //     Dosage: "200 mg",
  //     Frequency: "Twice a day",
  //     Duration: "3-6 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food or milk",
  //     "Hindi Instructions": "दिन में दो बार, भोजन या दूध के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Sulfasalazine",
  //     Dosage: "500 mg",
  //     Frequency: "Twice a day",
  //     Duration: "6 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with a full glass of water",
  //     "Hindi Instructions": "दिन में दो बार, एक गिलास पानी के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Leflunomide",
  //     Dosage: "20 mg",
  //     Frequency: "Once a day",
  //     Duration: "6 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with or without food",
  //     "Hindi Instructions": "दिन में एक बार, भोजन के साथ या बिना ले सकते हैं",
  //   },
  //   {
  //     "Medicine Name": "Prednisolone",
  //     Dosage: "Start with 5 mg, reduce by 1 mg every 2 weeks",
  //     Frequency: "Once a day, then reduce to alternate days after 4 weeks",
  //     Duration: "2 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Start with 5 mg, reduce dose and frequency gradually",
  //     "Hindi Instructions":
  //       "दिन में एक बार 5 मिग्रा से शुरू करें, हर दो सप्ताह में 1 मिग्रा कम करें, 4 सप्ताह बाद एक दिन छोड़ कर लें",
  //   },
  //   {
  //     "Medicine Name": "Naproxen",
  //     Dosage: "250 mg",
  //     Frequency: "Twice a day",
  //     Duration: "2 weeks",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food or milk",
  //     "Hindi Instructions": "दिन में दो बार, भोजन या दूध के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Ibuprofen",
  //     Dosage: "400 mg",
  //     Frequency: "Every 6-8 hours",
  //     Duration: "1 week",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food or milk",
  //     "Hindi Instructions": "हर 6-8 घंटे में, भोजन या दूध के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Diclofenac",
  //     Dosage: "50 mg",
  //     Frequency: "Twice a day",
  //     Duration: "2 weeks",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food or milk",
  //     "Hindi Instructions": "दिन में दो बार, भोजन या दूध के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Celecoxib",
  //     Dosage: "200 mg",
  //     Frequency: "Once a day",
  //     Duration: "As pain occurs",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with or without food",
  //     "Hindi Instructions":
  //       "दर्द होने पर दिन में एक बार, भोजन के साथ या बिना ले सकते हैं",
  //   },
  //   {
  //     "Medicine Name": "Meloxicam",
  //     Dosage: "15 mg",
  //     Frequency: "Once a day",
  //     Duration: "3 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food or milk",
  //     "Hindi Instructions": "दिन में एक बार, भोजन या दूध के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Allopurinol",
  //     Dosage: "Start with 100 mg, increase by 100 mg monthly",
  //     Frequency: "Once a day",
  //     Duration: "6 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Increase as tolerated, up to 300 mg",
  //     "Hindi Instructions":
  //       "दिन में एक बार 100 मिग्रा से शुरू करें, सहनशीलता के अनुसार हर महीने 100 मिग्रा बढ़ाएं, अधिकतम 300 मिग्रा तक",
  //   },
  //   {
  //     "Medicine Name": "Febuxostat",
  //     Dosage: "40 mg",
  //     Frequency: "Once a day",
  //     Duration: "6 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with or without food",
  //     "Hindi Instructions": "दिन में एक बार, भोजन के साथ या बिना ले सकते हैं",
  //   },
  //   {
  //     "Medicine Name": "Calcium + Vitamin D",
  //     Dosage: "As prescribed",
  //     Frequency: "Once a day",
  //     Duration: "Ongoing",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take with food",
  //     "Hindi Instructions": "दिन में एक बार, भोजन के साथ लें",
  //   },
  //   {
  //     "Medicine Name": "Alendronate",
  //     Dosage: "70 mg",
  //     Frequency: "Once a week",
  //     Duration: "6-12 months",
  //     TotalMedicines: "20 tablets",
  //     Instructions: "Take on an empty stomach with water",
  //     "Hindi Instructions": "सप्ताह में एक बार, खाली पेट पानी के साथ लें",
  //   },
  // ];
  let historyMedicine = medicineData.value.filter((medicine) => medicine['History-Medicine'] == 'H') 
  displayResults(historyMedicine);
  
  searchInput.value.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      resultsTable.value
        .querySelectorAll('input[type="checkbox"]')
        .forEach((checkbox) => {
          const id = parseInt(checkbox.dataset.id);
          checkbox.checked = true;
          
          if (!selectedMedicines.value.has(id)) {
            selectedMedicines.value.add(id);
          }
        });
      displaySelectedMedicines();
    } else if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      event.stopPropagation();
      handleArrowKeys(event);
    } else if (event.key === " ") {
      event.preventDefault();
      handleSpaceBar(event);
    }
  });

  searchInput.value.addEventListener("input", function () {
    const query = searchInput.value.value.trim();
    // console.log('query: ', query);
    let filteredResults = filterResults(query);
    if(searchString.value.trim() == ''){
      filteredResults = medicineData.value.filter((medicine) => medicine['History-Medicine'] == 'H') 
    }
    displayResults(filteredResults);
  });


});

// Add your custom JavaScript code here
function displayResults(results) {
  resultsTable.value.innerHTML =
    "<tr><th>Select</th><th>Medicine Name</th><th>Dosage</th><th>Frequency</th><th>Formulation</th><th>Duration</th><th>Total Medicines</th><th>Instructions</th></tr>";
  results.forEach((medicine,index) => {
    const row = resultsTable.value.insertRow();
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.dataset.id = medicine.id
    checkbox.dataset.name = medicine["Medicine Name"];
    checkbox.classList.add("fromhistory");
    checkbox.addEventListener("change", function () {
      toggleSelectedMedicine(medicine, row);
    });
    checkbox.checked = selectedMedicines.value.has(medicine["id"]);
    row.insertCell(0).appendChild(checkbox);
    row.insertCell(1).textContent = medicine["Medicine Name"];
    row.insertCell(2).appendChild(createDosageSelect(medicine.Dosage));
    row.insertCell(3).appendChild(createFrequencySelect(medicine.Frequency));
    row.insertCell(4).textContent = medicine.Formulation;
    row.insertCell(5).appendChild(createDurationSelect(medicine.Duration));
    row.insertCell(6).textContent = medicine.TotalMedicines;
    row.insertCell(7).textContent = medicine.Instructions;
  });
  displaySelectedMedicines();
}
function displaySelectedMedicines() {
  selectedTable.value.innerHTML =
  "<tr><th>Medicine Name</th><th>Dosage</th><th>Frequency</th><th>Formulation</th><th>Duration</th><th>Total Medicines</th><th>Instructions</th></tr>";
  selectedMedicine.value = [];

  medicineData.value.forEach((medicine) => {
    if (selectedMedicines.value.has(medicine['id'])) {
      console.log('medicine: ', medicine);
      const row = selectedTable.value.insertRow();
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.dataset.id = medicine.id
      checkbox.dataset.name = medicine["Medicine Name"];
      checkbox.classList.add("fromhistory");
      checkbox.addEventListener("change", function () {
        toggleSelectedMedicine(medicine, row);
      });
      row.insertCell(0).textContent = medicine["Medicine Name"];
      row.insertCell(1).textContent = medicine.Dosage;
      row.insertCell(2).textContent = medicine.Frequency;
      row.insertCell(3).textContent = medicine.Formulation;
      row.insertCell(4).textContent = medicine.Duration;
      row.insertCell(5).textContent = medicine.TotalMedicines;
      row.insertCell(6).textContent = medicine.Instructions;
      selectedMedicine.value.push(medicine);
    }
  });
}

function toggleSelectedMedicine(medicine, row, isCheckbox) {
  // alert('Please select')
  const checkbox = isCheckbox ? row : row.querySelector("input.fromhistory");
  const medicineName = checkbox.dataset.name;
  const medicineId = parseInt(checkbox.dataset.id);
  console.log(
    "selectedMedicines.value.has(medicineName): ",
    selectedMedicines.value.has(medicineName),medicineId
  );
  if (selectedMedicines.value.has(medicineId)) {
    selectedMedicines.value.delete(medicineId);
    row.classList.remove("selected");
  } else {
    selectedMedicines.value.add(medicineId);
    row.classList.add("selected");
  }
  // console.log(
  //   "selectedMedicines. afedsfsdfsdfsdf: ",
  //   selectedMedicines.value.has(medicineId)
  // );

  setTimeout(() => {
    displaySelectedMedicines();
  }, 0);
}

function filterResults(query) {
  const lowerCaseQuery = query.toLowerCase();
  return medicineData.value.filter((medicine) => medicine["Medicine Name"]?.toLowerCase()?.includes(lowerCaseQuery));
}

let selectedIndex = 0; // To keep track of the selected index

function handleArrowKeys(event) {
  if (event.key === "ArrowDown" && selectedIndex < resultsTable.value.rows.length - 1) {
    selectedIndex++;
  } else if (event.key === "ArrowUp" && selectedIndex > 0) {
    selectedIndex--;
  }

  // Update the selected row
  const rows = resultsTable.value.querySelectorAll("tbody tr");
  rows.forEach((row, index) => {
    row.classList.toggle("selected", index === selectedIndex);
  });

  // Scroll to the selected row if it's not visible
  if (selectedIndex > 0) {
    const selectedRow = rows[selectedIndex];
    selectedRow?.scrollIntoView({
      block: "nearest",
      inline: "start",
    });
  }
}

function handleSpaceBar(event) {
  if (event.key === " ") {
    event.preventDefault(); // Prevent scrolling the page when pressing space bar
    event.stopPropagation();

    const selectedRow = resultsTable.value?.querySelector("tbody tr.selected");
    const checkbox = selectedRow?.querySelector("input[type='checkbox']");

    if (checkbox) {
      checkbox.checked = !checkbox.checked; // Toggle checkbox on space bar
      toggleSelectedMedicine(resultsTable.value.rows[selectedIndex], checkbox, true);
      // Check if the selected medicine is not already added
      // if (!selectedMedicines.value.has(checkbox.dataset?.name)) {
      //   // selectedMedicines.value.add(checkbox.dataset?.name);
      //   // Add the selected medicine to the selected medicines table
      //   const selectedMedicine = medicineData.value.find(
      //     (medicine) => medicine["Medicine Name"] === checkbox.dataset.name
      //   );

      //   if (selectedMedicine) {
      //     const row = selectedTable.value.insertRow();
      //     row.insertCell(0).textContent = selectedMedicine["Medicine Name"];
      //     row.insertCell(1).textContent = getSelectedDosage(
      //       selectedMedicine["Medicine Name"]
      //     );
      //     row.insertCell(2).textContent = selectedMedicine.Frequency;
      //     row.insertCell(3).textContent = selectedMedicine.Duration;
      //     row.insertCell(4).textContent = selectedMedicine.TotalMedicines;
      //     row.insertCell(5).textContent = selectedMedicine.Instructions;
      //   }
      // }
    }
  }
}

function createDosageSelect(selectedDosage) {
  const dosageSelect = document.createElement("select");
  const dosages = new Set(medicineData.value.map((value) => value.Dosage).filter((value) => value))
  // console.log('dosages: ', dosages);
  // dosages[
  //   "15 mg",
  //   "200 mg",
  //   "500 mg",
  //   "20 mg",
  //   "Start with 5 mg, reduce by 1 mg every 2 weeks",
  //   "400 mg",
  //   "As prescribed",
  //   "70 mg",
  //   "Start with 100 mg, increase by 100 mg monthly",
  // ];
  const defaultOption = document.createElement("option");
  defaultOption.value = "";
  defaultOption.text = "Select Dosage";
  defaultOption.selected = true;
  defaultOption.hidden = true;
  defaultOption.disabled = true;
  
  dosageSelect.appendChild(defaultOption);

  dosages.forEach((dosage) => {
    const option = document.createElement("option");
    option.value = dosage;
    option.text = dosage;
    if (dosage === selectedDosage) {
      option.selected = true;
    }
    dosageSelect.appendChild(option);
  });
  dosageSelect.setAttribute('id','dosage-dropdown');
  dosageSelect.addEventListener("change",dosageOnchange);
  dosageSelect.classList.add('form-select')

  return dosageSelect;
}

function getSelectedDosage(medicineName) {
  const selectedRow = resultsTable.value.querySelector(`td[data-name="${medicineName}"]`);
  return selectedRow ? selectedRow.querySelector("select").value : "";
}

function createFrequencySelect(selectedFrequency) {
  const frequencySelect = document.createElement("select");
  const frequencies = new Set(medicineData.value.map((value) => value.Frequency).filter((value) => value))
  // const frequencies = [
  //   "Once a week",
  //   "Twice a week",
  //   "Once a day",
  //   "Every 6-8 hours",
  //   "Once a day, then reduce to alternate days after 4 weeks",
  // ];
  const defaultOption = document.createElement("option");
  defaultOption.value = "";
  defaultOption.text = "Select Frequency";
  defaultOption.selected = true;
  defaultOption.hidden = true;
  defaultOption.disabled = true;

  frequencySelect.appendChild(defaultOption);
  
  frequencies.forEach((frequency) => {
    const foption = document.createElement("option");
    foption.value = frequency;
    foption.text = frequency;
    if (frequency === selectedFrequency) {
      foption.selected = true;
    }
    frequencySelect.appendChild(foption);
  });
  frequencySelect.classList.add('form-select')
  frequencySelect.addEventListener("change",frequencyOnchange);
  return frequencySelect;
}

function createDurationSelect(selectedDuration) {
  const durationSelect = document.createElement("select");
  const durations = new Set(medicineData.value.map((value) => value.Duration).filter((value) => value))
  // console.log(medicineData.value, "hereere")
  // const durations = [
  //   "1 Month",
  //   "2 months",
  //   "3 months",
  //   "6 months",
  //   "Ongoing",
  //   "6-12 months",
  // ];
  const defaultOption = document.createElement("option");
  defaultOption.value = "";
  defaultOption.text = "Select Durations";
  defaultOption.selected = true;
  defaultOption.hidden = true;
  defaultOption.disabled = true;

  durationSelect.appendChild(defaultOption);

  durations.forEach((duration) => {
    const doption = document.createElement("option");
    doption.value = duration;
    doption.text = duration;
    if (duration === selectedDuration) {
      doption.selected = true;
    }
    durationSelect.appendChild(doption);
  });
  durationSelect.addEventListener("change",durationOnchange);
  durationSelect.classList.add('form-select')
  return durationSelect;
}
function dosageOnchange(event){
  const selectedValue = event.target.value;
  const checkBox = $(event.target).closest('tr').find('input[type=checkbox')
  const dataId = $(checkBox).data('id');
  medicineData.value.forEach((v) => {
    if(v.id == dataId) {
      v.Dosage = selectedValue
    }
  })
}
function frequencyOnchange(event){
  const selectedValue = event.target.value;
  const checkBox = $(event.target).closest('tr').find('input[type=checkbox')
  const dataId = $(checkBox).data('id');
  medicineData.value.forEach((v) => {
    if(v.id == dataId) {
      v.Frequency = selectedValue
    }
  })
}
function durationOnchange(event){
  const selectedValue = event.target.value;
  const checkBox = $(event.target).closest('tr').find('input[type=checkbox')
  const dataId = $(checkBox).data('id');
  medicineData.value.forEach((v) => {
    if(v.id == dataId) {
      v.Duration = selectedValue
    }
  })
}


let getSavedData = () => {
  return {
    data: selectedMedicine.value,
    componentId: propsData.componentId,
    type: "widget",
    subType: "prescription"
  };
};

defineExpose({ getSavedData });
</script>
