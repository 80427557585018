import { createStore } from "vuex";

export default createStore({
  state: {
    csvToJsonData: {},
    medicineData:[]
  },
  mutations: {
    setCsvToJsonData(state, payload) {
      // console.log('payload: ', payload);
      localStorage.setItem('csvToJsonData', JSON.stringify(payload));
      state.csvToJsonData = payload;
    },
    setMedicineData(state, payload) {
      // console.log('payload: ', payload);
      localStorage.setItem('medicineData', JSON.stringify(payload));
      state.medicineData = payload;
    },
  },
  getters: {
    // get json data from local storage
    getCsvToJsonData(state) {
      let csvToJsonDataFromState = state.csvToJsonData;
      let csvToJsonFromLs = localStorage.getItem('csvToJsonData');
      // console.log('csvToJsonFromLs: ', csvToJsonFromLs);
      let finalData = csvToJsonFromLs != null ? JSON.parse(csvToJsonFromLs) : csvToJsonDataFromState;
      // console.log('finalData: ', typeof finalData, finalData);
      let jsonData = finalData != null ? finalData : state.csvToJsonData;
      return jsonData;
    },
    getMedicineData(state) {
      let medicineDataFromState = state.medicineData;
      let medicineDataFromLs = localStorage.getItem('medicineData');
      // console.log('csvToJsonFromLs: ', csvToJsonFromLs);
      let finalData = medicineDataFromLs != null ? JSON.parse(medicineDataFromLs) : medicineDataFromState;
      // console.log('finalData: ', typeof finalData, finalData);
      let jsonData = finalData != null ? finalData : state.medicineData;
      // console.log('medicine jsonData: ', typeof jsonData, jsonData);
      return jsonData;
    }
  }
});
