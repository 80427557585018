import { createApp } from 'vue'
import DocumentUploadComponent from '../DocumentUpload/DocumentUploadComponent.vue'
// import '../../sample.css';

export default class DocumentUpload{
    static get toolbox() {
      return {
        title: 'Document Upload',
        icon: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs><style>.cls-1{fill:#141f38;}</style></defs><title>document-upload-outline</title><path class="cls-1" d="M128,268.8h76.8a12.8,12.8,0,0,0,0-25.6H128a12.8,12.8,0,0,0,0,25.6Z"/><path class="cls-1" d="M281.6,294.4H128a12.8,12.8,0,0,0,0,25.6H281.6a12.8,12.8,0,0,0,0-25.6Z"/><path class="cls-1" d="M281.22,345.6H128a12.8,12.8,0,0,0,0,25.6H281.22a12.8,12.8,0,0,0,0-25.6Z"/><path class="cls-1" d="M422.4,333.82V153.6L268.94,0H76.8a64,64,0,0,0-64,64V448a64,64,0,0,0,64,64H358.4a63.38,63.38,0,0,0,23.33-4.47A89.54,89.54,0,1,0,422.4,333.82Zm-49.31-193H320a38.44,38.44,0,0,1-38.4-38.4V49.08ZM76.8,486.4A38.44,38.44,0,0,1,38.4,448V64A38.44,38.44,0,0,1,76.8,25.6H256v76.8a64,64,0,0,0,64,64h76.8V333.82A89.48,89.48,0,0,0,347,486.4Zm332.8,0a64,64,0,1,1,64-64A64.07,64.07,0,0,1,409.6,486.4Z"/><path class="cls-1" d="M418.65,387.75c-.19-.19-.41-.32-.61-.49s-.45-.38-.69-.56a12.67,12.67,0,0,0-2.76-1.66l-.09-.06h0a12.7,12.7,0,0,0-3.26-.81l-.83-.08a12.86,12.86,0,0,0-3.25.16l-.19.06a12.69,12.69,0,0,0-2.92,1c-.26.13-.5.25-.75.4a12.64,12.64,0,0,0-2.72,2l-25.6,25.6a12.8,12.8,0,0,0,18.1,18.1l3.75-3.75V448a12.8,12.8,0,0,0,25.6,0V427.7l3.75,3.75a12.8,12.8,0,0,0,18.1-18.1Z"/></svg>'
      };
    }
  
    /**
     * Allow to press Enter inside the CodeTool textarea
     *
     * @returns {boolean}
     * @public
     */
    static get enableLineBreaks() {
      return true;
    }
  
    constructor({ data, config, api }) {
      this.api = api;
      this.container = null;
      this.data = {
        text: data && data.text ? data.text : '',
      };
    }
  
    //   get shortcut() {
    //     return 'CTRL+E';
    //   }
  
    render() {
      let componentId = "documenUploadDiv" + Date.now()
      this.container = document.createElement('div');
      this.container.setAttribute('id', componentId);
      setTimeout(() => {
        createApp(DocumentUploadComponent, { componentId: componentId }).mount('#' + componentId)
      }, 500)
      return this.container;
    }
  
    save() {
      return this.data;
    }
  
  }