<template>
  <div class="wrapper pt-5" :id="'DasCalculator-' + componentId">
    <div class="container-fluid pl-0">
      <div class="editor_area p-5">
        <!-------DAS Calculator--------->
        <div>
          <p><span class="yellow_chip">DAS28 Calculator</span></p>
          <section class="py-5">
            <div class="container">
              <div class="row">
                <div class="col-md-6 py-5 bg-custom text-white text-center">
                  <div class="card-body">
                    <div id="pallete">
                      <p>Select the state and click/tap on the joint</p>
                      <button type="button" class="btn btn-sm btn-normal">Normal</button>
                      <button type="button" class="btn btn-sm btn-tender">Tender</button>
                      <button type="button" class="btn btn-sm btn-swollen">
                        Swollen
                      </button>
                      <button type="button" class="btn btn-sm btn-both">Both</button>
                    </div>
                    <svg
                      xmlns:svg="http://www.w3.org/2000/svg"
                      xmlns="http://www.w3.org/2000/svg"
                      :id="'today-homer' + componentId"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 744.09448819 1020.3622047"
                    >
                      <g>
                        <path
                          id="path5"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 151.57988,462.39029 -80.718135,71.80187 137.438965,0 -36.35952,-73.95593 z"
                          class="r-hand"
                        />
                        <path
                          id="path7"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 67.26751,529.65602 17.662532,3.82153 c 0,0 -32.868858,85.12958 -44.720598,97.46311 -28.94833,14.90811 23.397826,-74.16969 27.058066,-101.28464 z"
                          class="finger5"
                        />
                        <path
                          id="path9"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 96.745025,535.92489 16.133875,0 c 0,0 -38.218985,105.76584 -50.070715,118.09936 -28.948338,14.90813 30.27656,-90.98442 33.93684,-118.09936 z"
                          class="finger4"
                        />
                        <path
                          id="path11"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 131.71069,534.90947 16.1339,0 c 0,0 -45.86205,121.05194 -57.713777,133.38546 -28.94834,14.90813 37.919607,-106.27054 41.579877,-133.38546 z"
                          class="finger3"
                        />
                        <path
                          id="path13"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 165.13377,534.90947 16.13388,0 c 0,0 -38.21897,105.76582 -50.0707,118.09934 -28.94833,14.90813 30.27656,-90.98442 33.93682,-118.09934 z"
                          class="finger2"
                        />
                        <path
                          id="path15"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 196.66566,534.19216 16.72539,0 c 0,0 10.88073,81.85411 -2.32149,82.36181 -11.62804,0.4472 -14.4039,-82.36181 -14.4039,-82.36181 z"
                          class="finger1"
                        />
                        <path
                          id="path17"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 573.34052,465.6786 86.61404,71.73271 -147.47792,0 39.0153,-73.88472 z"
                          class="l-hand"
                        />
                        <path
                          id="path19"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 658.38802,533.0962 -18.10365,5.35015 c 0,0 31.82136,85.17922 42.87423,97.51852 C 710.15569,650.88 661.80156,560.22386 658.38802,533.0962 Z"
                          class="finger10"
                        />
                        <path
                          id="path21"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 625.21598,535.38914 -15.04642,0 c 0,0 35.6429,105.8155 46.69577,118.15481 26.99708,14.91511 -28.23581,-91.02715 -31.64935,-118.15481 z"
                          class="finger9"
                        />
                        <path
                          id="path23"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 590.79591,533.43209 -16.1339,0 c 0,0 45.86205,121.05194 57.71378,133.38546 28.94834,14.90813 -37.91961,-106.27054 -41.57988,-133.38546 z"
                          class="finger3"
                        />
                        <path
                          id="path25"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 560.42663,536.40456 -15.04644,0 c 0,0 35.64291,105.8155 46.69578,118.15481 26.99709,14.91513 -28.2358,-91.02713 -31.64934,-118.15481 z"
                          class="finger7"
                        />
                        <path
                          id="path27"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 524.96861,534.1666 -15.16267,0 c 0,0 -9.8641,81.91931 2.1046,82.42742 10.54158,0.44754 13.05807,-82.42742 13.05807,-82.42742 z"
                          class="finger6"
                        />
                        <path
                          id="path29"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 301.12967,863.11421 37.02249,-1.01542 c 27.00438,35.82954 -57.11022,52.57366 -78.15857,95.45057 l -132.66393,-7.10803 22.59685,-31.45651 z"
                          class="r-foot"
                        />
                        <path
                          id="path31"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 429.93745,862.30653 -37.02249,-1.01542 c -27.00438,35.82954 57.11022,52.57366 78.15857,95.45057 l 132.66393,-7.10803 -19.53963,-26.10637 z"
                          class="l-foot"
                        />
                        <path
                          id="path33"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 127.32966,957.54936 13.36923,-1.01545 c 2.38239,4.70469 -23.21841,47.54559 -20.05385,14.72378 2.60065,-26.97317 6.68462,-13.70833 6.68462,-13.70833 z"
                          class="finger15"
                        />
                        <path
                          id="path35"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 161.06612,956.84595 13.36923,-1.01545 c 2.38237,4.70469 -23.21842,47.5456 -20.05385,14.72378 2.60066,-26.97317 6.68462,-13.70833 6.68462,-13.70833 z"
                          class="finger14"
                        />
                        <path
                          id="path37"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 194.48921,956.84595 13.36922,-1.01545 c 2.38238,4.70469 -23.2184,47.5456 -20.05384,14.72378 2.60065,-26.97317 6.68462,-13.70833 6.68462,-13.70833 z"
                          class="finger13"
                        />
                        <path
                          id="path39"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 227.39809,956.33824 13.36923,-1.01544 c 2.38236,4.70466 -23.21842,47.5456 -20.05386,14.72376 2.60066,-26.97316 6.68463,-13.70832 6.68463,-13.70832 z"
                          class="finger12"
                        />
                        <path
                          id="path41"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 261.84955,955.83052 13.36924,-1.01543 c 2.38237,4.70468 -23.21842,47.54561 -20.05385,14.72376 2.60066,-26.97315 6.68461,-13.70833 6.68461,-13.70833 z"
                          class="finger11"
                        />
                        <path
                          id="path43"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 467.68594,955.81061 -12.36887,-1.0172 c -2.20413,4.71292 21.48105,47.62889 18.55329,14.74954 -2.40606,-27.02041 -6.18442,-13.73234 -6.18442,-13.73234 z"
                          class="finger16"
                        />
                        <path
                          id="path45"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 503.16579,955.81047 -12.36886,-1.0172 c -2.20411,4.7129 21.48105,47.62883 18.55331,14.74953 -2.40608,-27.0204 -6.18445,-13.73233 -6.18445,-13.73233 z"
                          class="finger17"
                        />
                        <path
                          id="path47"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 536.58889,955.81047 -12.36887,-1.0172 c -2.20411,4.7129 21.48107,47.62883 18.55331,14.74953 -2.40606,-27.0204 -6.18444,-13.73233 -6.18444,-13.73233 z"
                          class="finger18"
                        />
                        <path
                          id="path49"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 568.46936,954.28731 -12.36886,-1.0172 c -2.20412,4.7129 21.48106,47.62889 18.55329,14.74955 -2.40605,-27.0204 -6.18443,-13.73235 -6.18443,-13.73235 z"
                          class="finger19"
                        />
                        <path
                          id="path51"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 600.86404,955.30274 -12.36887,-1.01721 c -2.20411,4.7129 21.48107,47.62887 18.5533,14.74955 -2.40607,-27.0204 -6.18443,-13.73234 -6.18443,-13.73234 z"
                          class="finger20"
                        />
                        <path
                          id="path53"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 292.3713,565.85371 c -0.40488,59.44728 -2.3399,85.65058 13.86874,140.41099 l 4.95314,145.74137 19.8125,0.97813 4.95309,-148.67575 c 7.88604,-35.05193 9.53414,-83.32789 6.93438,-136.49849 z"
                          class="r-leg"
                        />
                        <path
                          id="path55"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 437.6668,568.58175 c 0.41636,59.43889 2.40622,85.6385 -14.26199,140.39117 l -5.09357,145.72081 -20.37426,0.97799 -5.09354,-148.65478 c -8.10963,-35.04696 -9.80445,-83.31611 -7.13099,-136.47921 z"
                          class="l-leg"
                        />
                        <path
                          id="path59"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 493.62046,242.84943 30.15103,132.75755 54.65241,81.60521 -26.57096,2.29292 -52.4212,-62.04092 -49.7815,-127.30862 z"
                          class="l-arm"
                        />
                        <path
                          id="path57"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 238.31699,244.22588 -30.15103,132.75755 -54.65241,81.60521 26.57096,2.29292 52.4212,-62.04092 49.78151,-127.30862 z"
                          class="r-arm"
                        />
                        <path
                          id="path61"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 340.97832,436.9289 -35.54753,21.08914 c -1.1614,-106.71154 -98.20971,-212.98281 -52.19057,-218.60486 134.37809,-16.41665 61.341,-55.54958 112.10073,-56.29802 52.31438,-0.77141 -23.68612,40.88137 118.57002,58.07798 46.24312,5.59009 -58.80338,110.11336 -57.78393,215.37046 l -19.01379,-10.90815 -15.70708,-7.99932 0,18.93417 C 497.25089,525.523 430.52325,592.27162 365.37194,559.66163 300.85432,592.55131 231.8222,525.523 340.97833,456.5636 Z"
                          class="sexy-body"
                        />
                        <path
                          id="path63"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="M 306.1309,86.691295 C 296.17414,201.9223 364.81247,207.08892 365.00897,207.11217 411.2121,195.22215 428.05729,157.20431 420.37196,88.468071 409.62047,33.228073 316.0843,32.987018 306.1309,86.691295 Z"
                          class="face"
                        />
                        <path
                          id="path79"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 304.83059,128.94369 c -10.93902,2.48445 -12.20122,4.9223 -8.41464,17.69944 3.78659,12.77714 15.98782,15.97143 15.98782,15.97143 -3.04875,-11.58449 -5.84016,-22.99189 -7.57318,-33.67087 z"
                          class="r-ear"
                        />
                        <path
                          id="path81"
                          style="
                            fill: #ffffff;
                            stroke: #000000;
                            stroke-width: 3;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 4;
                            stroke-dasharray: none;
                            stroke-opacity: 1;
                          "
                          d="m 424.17367,128.27483 c 10.83537,2.48549 12.0856,4.92438 8.33491,17.70695 -3.75072,12.78256 -15.83632,15.97821 -15.83632,15.97821 3.01986,-11.58941 5.78482,-23.00165 7.50141,-33.68516 z"
                          class="l-ear"
                        />
                      </g>
                      <g class="jnt-side">
                        <text
                          x="100"
                          y="200"
                          font-family="Arial"
                          font-size="80"
                          fill="white"
                        >
                          R
                        </text>
                        <text
                          x="600"
                          y="200"
                          font-family="Arial"
                          font-size="80"
                          fill="white"
                        >
                          L
                        </text>
                      </g>
                      <g class="jnt-container">
                        <circle
                          class="sm-joint c28"
                          id="jnt1"
                          cx="206.11914"
                          cy="534.19226"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt2"
                          cx="171.94119"
                          cy="534.19226"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt3"
                          cx="139.21761"
                          cy="534.91003"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt4"
                          cx="106.49406"
                          cy="534.19208"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt5"
                          cx="73.844749"
                          cy="535.00275"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt6"
                          cx="209.02789"
                          cy="572.96515"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt7"
                          cx="158.8517"
                          cy="572.96515"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt8"
                          cx="123.87238"
                          cy="577.55096"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt9"
                          cx="91.223053"
                          cy="572.96515"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt10"
                          cx="58.49947"
                          cy="571.52911"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt11"
                          cx="515.32495"
                          cy="534.19226"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt12"
                          cx="548.77563"
                          cy="534.19226"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt13"
                          cx="582.2265"
                          cy="534.19226"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt14"
                          cx="616.40436"
                          cy="534.9101"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt15"
                          cx="650.38202"
                          cy="535.88831"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt16"
                          cx="512.41614"
                          cy="572.24719"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt17"
                          cx="562.59222"
                          cy="572.96509"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt18"
                          cx="598.29877"
                          cy="577.5509"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt19"
                          cx="629.49377"
                          cy="572.96515"
                          r="13"
                        />
                        <circle
                          class="sm-joint c28"
                          id="jnt20"
                          cx="663.67175"
                          cy="572.24713"
                          r="13"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt21"
                          cx="161.76057"
                          cy="455.21011"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt22"
                          cx="561.86505"
                          cy="454.49207"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt23"
                          cx="219.06837"
                          cy="378.19473"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt24"
                          cx="508.45294"
                          cy="377.38409"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt25"
                          cx="250.83037"
                          cy="258.68878"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt26"
                          cx="475.64886"
                          cy="258.43359"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt27"
                          cx="318.00336"
                          cy="702.67035"
                          r="30"
                        />
                        <circle
                          class="big-joint c28"
                          id="jnt28"
                          cx="411.08371"
                          cy="702.67035"
                          r="30"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt29"
                          cx="321.07611"
                          cy="171.52905"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt30"
                          cx="406.8158"
                          cy="170.11461"
                          r="13"
                        />
                        <circle
                          class="big-joint c68"
                          id="jnt31"
                          cx="318.00336"
                          cy="552.60431"
                          r="30"
                        />
                        <circle
                          class="big-joint c68"
                          id="jnt32"
                          cx="411.08371"
                          cy="551.88629"
                          r="30"
                        />
                        <circle
                          class="big-joint c68"
                          id="jnt33"
                          cx="314.65637"
                          cy="849.14612"
                          r="30"
                        />
                        <circle
                          class="big-joint c68"
                          id="jnt34"
                          cx="412.24438"
                          cy="848.88605"
                          r="30"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt35"
                          cx="282.48441"
                          cy="927.30499"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt36"
                          cx="247.05232"
                          cy="926.58691"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt37"
                          cx="214.36029"
                          cy="926.0979"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt38"
                          cx="181.63667"
                          cy="927.07593"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt39"
                          cx="148.68129"
                          cy="926.58691"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt40"
                          cx="444.8905"
                          cy="927.02966"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt41"
                          cx="482.30377"
                          cy="926.31158"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt42"
                          cx="514.76385"
                          cy="926.54059"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt43"
                          cx="547.9245"
                          cy="926.5141"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt44"
                          cx="579.18884"
                          cy="927.77875"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt45"
                          cx="334.08475"
                          cy="220.43959"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt46"
                          cx="286.4838"
                          cy="230.70221"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt47"
                          cx="396.50665"
                          cy="220.65253"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt48"
                          cx="437.85431"
                          cy="233.39946"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt49"
                          cx="190.84814"
                          cy="492.54718"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt50"
                          cx="534.85217"
                          cy="493.87775"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt51"
                          cx="140.67201"
                          cy="615.32819"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt52"
                          cx="101.10674"
                          cy="631.37872"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt53"
                          cx="74.497665"
                          cy="615.32831"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt54"
                          cx="45.63271"
                          cy="605.39221"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt55"
                          cx="580.77203"
                          cy="614.61029"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt56"
                          cx="621.86578"
                          cy="631.3786"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt57"
                          cx="648.43781"
                          cy="618.38556"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt58"
                          cx="677.30273"
                          cy="606.96722"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt59"
                          cx="265.64563"
                          cy="953.25891"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt60"
                          cx="232.19495"
                          cy="953.25879"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt61"
                          cx="198.74416"
                          cy="952.54083"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt62"
                          cx="165.29337"
                          cy="953.25879"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt63"
                          cx="134.0242"
                          cy="953.25891"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt64"
                          cx="464.16864"
                          cy="952.54071"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt65"
                          cx="498.34659"
                          cy="953.25879"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt66"
                          cx="531.07013"
                          cy="953.25879"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt67"
                          cx="563.7937"
                          cy="952.54071"
                          r="13"
                        />
                        <circle
                          class="sm-joint c68"
                          id="jnt68"
                          cx="594.33569"
                          cy="953.97681"
                          r="13"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="col-md-6 py-5 border">
                  <h4 class="pb-4 form-title">Joint Scores</h4>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="form-title">28 Joints</h5>
                      <table class="table table-borderless">
                        <tr>
                          <td>Tender</td>
                          <td>
                            <input
                              type="text"
                              id="t28-res"
                              class="input-res"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Swollen</td>
                          <td>
                            <input
                              type="text"
                              id="s28-res"
                              class="input-res"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-6">
                      <h5 class="form-title">66/68 Joints</h5>
                      <table class="table table-borderless">
                        <tr>
                          <td>Tender</td>
                          <td>
                            <input
                              type="text"
                              id="t68-res"
                              class="input-res"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Swollen</td>
                          <td>
                            <input
                              type="text"
                              id="s68-res"
                              class="input-res"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="pb-4 form-title">Assessment</h4>
                      <div class="table">
                        <div class="row mb-14">
                          <div class="col-5">ESR (mm/hr)</div>
                          <div class="col-7"><div :id="'slider-esr' + componentId"></div></div>
                        </div>
                        <div class="row mb-14">
                          <div class="col-5">CRP (mg/L)</div>
                          <div class="col-7"><div :id="'slider-crp' + componentId"></div></div>
                        </div>
                        <div class="row mb-14">
                          <div class="col-5">Patient Global Health</div>
                          <div class="col-7"><div :id="'slider-pgh' + componentId"></div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-12">
                      <h4 class="pb-4 form-title">Disease Activity Scores (DAS)</h4>
                      <table class="table table-borderless">
                        <tr>
                          <td>DAS 28</td>
                          <td>
                            <input
                              type="text"
                              id="das28-res"
                              class="input-res"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>DAS 28 CRP</td>
                          <td>
                            <input
                              type="text"
                              id="das28crp-res"
                              class="input-res"
                              value="0"
                              disabled
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!--------DAS Calculator END----------->
      </div>
    </div>
  </div>
  <!--wrapper-->
</template>
<script setup>
import { ref, onMounted, onBeforeMount, defineProps, watch } from "vue";
import homer from "../composables/homer";
import noUiSlider from 'nouislider';
import wNumb from 'wnumb/wNumb.js';
import 'nouislider/dist/nouislider.css';

let propsData = defineProps({
  componentId: {
    default: null,
  }
})

const { initHomer } = homer(noUiSlider, wNumb, propsData.componentId, '#DasCalculator-' + propsData.componentId);

onBeforeMount(() => {
  handleLoadingScripts();
});

onMounted(() => {
  initHomer();
});

let handleLoadingScripts = () => {
  // Function to check if a style is already loaded
  const isStyleLoaded = (href) => {
    return Array.from(document.getElementsByTagName("link")).some((style) =>
      style.href.includes(href)
    );
  };

  // Load Homer CSS
  if (!isStyleLoaded("plugins/calculator/homer.css")) {
    const homerCSS = document.createElement("link");
    homerCSS.href = "plugins/calculator/homer.css";
    homerCSS.rel = "stylesheet";
    document.head.appendChild(homerCSS);
  }
};
</script>
